/**
 * @generated SignedSource<<8cf437fc6a9b4bbf5adb49741fa35fe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatsCollectionCell_collection$data = {
  readonly isVerified: boolean;
  readonly logo: string | null;
  readonly name: string;
  readonly slug: string;
  readonly " $fragmentType": "StatsCollectionCell_collection";
};
export type StatsCollectionCell_collection$key = {
  readonly " $data"?: StatsCollectionCell_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatsCollectionCell_collection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatsCollectionCell_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "592cc04def6bb7f1b7095e5210df6e5c";

export default node;

import { useState, useCallback, SetStateAction, Dispatch } from "react"
import { SelectOption } from "@/design-system/Select"
import { useChainOptions } from "@/hooks/useChains/useChainOptions"
import { useQueryParamState } from "@/hooks/useQueryParamState"
import {
  DEFAULT_CATEGORY,
  DEFAULT_SORT_BY,
  useCategoryOptions,
  useSortByTimeOptions,
} from "./constants"
import { selectOptionSerializer, getOptionFromValue } from "./utils"

type UseRankingsPageFiltersInitialValues = {
  isLoadingNewFilter: boolean
}

export const useRankingsPageFilters = (
  initialValues: UseRankingsPageFiltersInitialValues,
) => {
  const chainOptions = useChainOptions()
  const categoryOptions = useCategoryOptions({ includeNew: true })
  const sortByOptions = useSortByTimeOptions()

  const [category, setCategory] = useQueryParamState<SelectOption>("category", {
    serializer: opt =>
      opt.value === DEFAULT_CATEGORY ? undefined : selectOptionSerializer(opt),
    defaultValue: categoryOptions[0],
    deserializer: value =>
      getOptionFromValue(value, categoryOptions, categoryOptions[0]),
  })

  const [sortBy, setSortBy] = useQueryParamState<SelectOption>("sortBy", {
    serializer: opt =>
      opt.value === DEFAULT_SORT_BY ? undefined : selectOptionSerializer(opt),
    defaultValue: sortByOptions[2],
    deserializer: value =>
      getOptionFromValue(value, sortByOptions, sortByOptions[0]),
  })

  const [chain, setChain] = useQueryParamState<SelectOption>("chain", {
    serializer: opt =>
      opt.value === "all" ? undefined : selectOptionSerializer(opt),
    defaultValue: chainOptions[0],
    deserializer: value =>
      getOptionFromValue(value, chainOptions, chainOptions[0]),
  })

  const [isLoadingNewFilter, setIsLoadingNewFilter] = useState(
    initialValues.isLoadingNewFilter,
  )

  const setFilter = useCallback(
    (
      setFilterStateFn: Dispatch<SetStateAction<SelectOption>>,
      option: SelectOption,
    ) => {
      setFilterStateFn(prevOption => {
        setIsLoadingNewFilter(prevOption.value !== option.value)
        return option
      })
    },
    [setIsLoadingNewFilter],
  )

  const handleSetSortBy = useCallback(
    (sortBy: SelectOption) => {
      setFilter(setSortBy, sortBy)
    },
    [setSortBy, setFilter],
  )

  const handleSetCategory = useCallback(
    (category: SelectOption) => {
      setFilter(setCategory, category)
    },
    [setCategory, setFilter],
  )

  const handleSetChain = useCallback(
    (chain: SelectOption) => {
      setFilter(setChain, chain)
    },
    [setChain, setFilter],
  )

  return {
    sortBy,
    setSortBy: handleSetSortBy,
    category,
    setCategory: handleSetCategory,
    chain,
    setChain: handleSetChain,
    isLoadingNewFilter,
    setIsLoadingNewFilter,
  }
}

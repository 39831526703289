/**
 * @generated SignedSource<<72d1dd62d8ad8c1ff2996e77f1a5dcde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StatsWatchlistButtonPinMutation$variables = {
  collectionId: string;
  isPinned: boolean;
};
export type StatsWatchlistButtonPinMutation$data = {
  readonly collections: {
    readonly updateWatchlistPin: boolean;
  };
};
export type StatsWatchlistButtonPinMutation = {
  response: StatsWatchlistButtonPinMutation$data;
  variables: StatsWatchlistButtonPinMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collectionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isPinned"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionMutationType",
    "kind": "LinkedField",
    "name": "collections",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "collection",
            "variableName": "collectionId"
          },
          {
            "kind": "Variable",
            "name": "isPinned",
            "variableName": "isPinned"
          }
        ],
        "kind": "ScalarField",
        "name": "updateWatchlistPin",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatsWatchlistButtonPinMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatsWatchlistButtonPinMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "67557c89d713c19a8c42ce4c3b136d56",
    "id": null,
    "metadata": {},
    "name": "StatsWatchlistButtonPinMutation",
    "operationKind": "mutation",
    "text": "mutation StatsWatchlistButtonPinMutation(\n  $collectionId: CollectionRelayID!\n  $isPinned: Boolean!\n) {\n  collections {\n    updateWatchlistPin(collection: $collectionId, isPinned: $isPinned)\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bec5178a1638845ee093c5d0ba8b1c3";

export default node;

import React, { useState } from "react"
import { Flex, Icon, classNames } from "@opensea/ui-kit"
import { useUpdateEffect } from "react-use"
import { Button } from "@/design-system/Button"
import { useRouter } from "@/hooks/useRouter"

export type PaginationArrowsProps = {
  className?: string
  currentPage: number
  setCurrentPage: (page: number) => unknown
  hasMore?: boolean
  loadNext?: () => Promise<unknown>
  pageSize: number
  limit?: number
}

export const PaginationArrows = ({
  className,
  currentPage,
  setCurrentPage,
  hasMore,
  loadNext,
  pageSize,
  limit,
}: PaginationArrowsProps) => {
  const router = useRouter()
  const [maxPage, setMaxPage] = useState(currentPage)

  useUpdateEffect(() => {
    setMaxPage(0)
  }, [router.query])

  const handleRightArrowButtonClick = async () => {
    const isMax = currentPage + 1 > maxPage
    if (isMax) {
      setMaxPage(currentPage + 1)
      if (loadNext !== undefined) {
        await loadNext()
      }
    }
    setCurrentPage(currentPage + 1)
  }

  const previousFrom = ((currentPage || 1) - 1) * pageSize + 1
  const previousTo = (currentPage || 1) * pageSize
  const leftArrowButtonText = `${previousFrom} - ${previousTo}`

  const nextFrom = (currentPage + 1) * pageSize + 1
  const nextTo = (currentPage + 2) * pageSize
  const rightArrowButtonText = `${nextFrom} - ${nextTo}`

  const shouldShowNextButton = limit ? nextFrom < limit : true

  return (
    <Flex
      className={classNames(
        "xl:shadow-none bottom-0 left-0 w-full justify-center bg-transparent py-2.5 shadow-current xl:pb-10 xl:pt-5",
        className,
      )}
    >
      <Button
        className="mr-2.5"
        disabled={currentPage === 0}
        variant="secondary"
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <Icon size={16} value="arrow_back_ios_new" />
        &nbsp;
        {leftArrowButtonText}
      </Button>
      {hasMore && shouldShowNextButton && (
        <Button
          className="ml-2.5"
          variant="secondary"
          onClick={handleRightArrowButtonClick}
        >
          {rightArrowButtonText}
          &nbsp;
          <Icon size={16} value="arrow_forward_ios" />
        </Button>
      )}
    </Flex>
  )
}

import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
  createContext,
} from "react"
import { useIsLessThanXl, Text, SpaceBetween } from "@opensea/ui-kit"
import { noop } from "lodash"
import { useUpdateEffect } from "react-use"
import styled, { css } from "styled-components"
import { PaginationArrows } from "@/components/common/PaginationArrows.react"
import { Block } from "@/design-system/Block"
import { FeatureTable } from "@/design-system/FeatureTable"
import { Flex } from "@/design-system/Flex"
import { SelectOption } from "@/design-system/Select"
import { SortDirection } from "@/design-system/Table/SortableHeader.react"
import useSortableHeaders from "@/design-system/Table/useSortableHeaders"
import { StatsTable } from "@/features/stats/components/StatsTable"
import {
  useChainOptions,
  getSmallChainOptions,
} from "@/hooks/useChains/useChainOptions"
import { useIsFloorPricePercentChangeEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { AnalyticsContextProvider } from "@/lib/analytics"
import { trackRankingsPage } from "@/lib/analytics/events/pageEvents"
import {
  RankingsPageFilterParams,
  trackCategoryFilter,
  trackChainFilter,
  trackSortByFilter,
  trackSorting,
} from "@/lib/analytics/events/rankingsEvents"
import { RankingsPageTop_data$data } from "@/lib/graphql/__generated__/RankingsPageTop_data.graphql"
import { RankingsPageTrending_data$data } from "@/lib/graphql/__generated__/RankingsPageTrending_data.graphql"
import { RankingsPageWatchlist_data$data } from "@/lib/graphql/__generated__/RankingsPageWatchlist_data.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { media } from "@/styles/styleUtils"
import { $nav_height, NAV_HEIGHT_PX } from "@/styles/variables"
import { RankingSelect } from "../RankingsSelect"
import { RankingsTableNoItems } from "../RankingsTableNoItems"
import {
  PAGE_SIZE,
  useSortByTimeOptions,
  useTrendingSortByTimeOptions,
  EVENT_SOURCE,
  useColumnSorts,
  useCategoryOptions,
  isFloorPricePercentChangeSupported,
} from "./constants"
import { RankingsPageLayout } from "./RankingsPageLayout.react"
import {
  ColumnSortKey,
  WatchlistRankingsPaginationFragment,
  MobileSort,
  TrendingRankingsPaginationFragment,
  TopRankingsPaginationFragment,
  RankingsPageTab,
} from "./types"
import { useRankingsPageFilters } from "./useRankingsPageFilters"
import { getMaxNumberOfCollections, getRows } from "./utils"

interface RankingsPageProps {
  paginationFragment:
    | TrendingRankingsPaginationFragment
    | TopRankingsPaginationFragment
    | WatchlistRankingsPaginationFragment
  currentTab: RankingsPageTab
  collections?:
    | RankingsPageTrending_data$data["trendingCollectionsByCategory"]
    | RankingsPageTop_data$data["topCollectionsByCategory"]
    | NonNullable<
        RankingsPageWatchlist_data$data["getAccount"]["user"]
      >["collectionWatchlist"]
  watchlist?: NonNullable<
    RankingsPageWatchlist_data$data["getAccount"]["user"]
  >["collectionWatchlist"]
  showMobileSort?: boolean
  showCategoryAndChainFilters?: boolean
  displayRankIndex?: boolean
  emptyView?: React.ReactNode
}

const SmallFiltersContainer = styled(Block)`
  ::-webkit-scrollbar {
    display: none;
  }

  ${media({
    md: css`
      display: none;
    `,
  })}
`
const LargeFiltersContainer = styled(Block)`
  display: none;
  ${media({
    md: css`
      display: block;
    `,
  })}
`

// We use this to watchlist collections locally without having
// to refetch the whole connection. We do it this way because doing Relay
// local state update for that connection seems to be impossible
// TODO: revisit in future and see if we can update that directly from the mutation's updater fn
type RankingsWatchlistContext = {
  watchingCollections: string[]
  setWatchingCollections: (watchingCollections: string[]) => unknown
  hiddenCollections: string[]
  setHiddenCollections: (hiddenCollections: string[]) => unknown
  pinnedCollections: { [slug: string]: boolean }
  setPinnedCollections: (pinnedCollections: {
    [slug: string]: boolean
  }) => unknown
}

const DEFAULT_CONTEXT: RankingsWatchlistContext = {
  watchingCollections: [],
  setWatchingCollections: noop,
  hiddenCollections: [],
  setHiddenCollections: noop,
  pinnedCollections: {},
  setPinnedCollections: noop,
}

export const RankingsWatchlistContext = createContext(DEFAULT_CONTEXT)
export const RankingsPage = ({
  currentTab,
  emptyView,
  paginationFragment,
  collections,
  watchlist,
  displayRankIndex = true,
  showCategoryAndChainFilters = true,
  showMobileSort = false,
}: RankingsPageProps) => {
  const t = useTranslate("rankings")
  const { data, hasNext, loadNext, isLoadingNext } = paginationFragment

  const sortByTimeOptions = useSortByTimeOptions()
  const trendingSortByTimeOptions = useTrendingSortByTimeOptions()
  const categoryOptionsWithoutAvatar = useCategoryOptions({
    excludeAvatars: true,
  })

  const noResults = collections ? collections.edges.length === 0 : true
  const isWatchlistTab = currentTab === "watchlist"
  const isTrendingTab = currentTab === "trending"
  const isTopTab = currentTab === "top"

  const {
    sortBy,
    setSortBy,
    category,
    setCategory,
    chain,
    setChain,
    isLoadingNewFilter,
    setIsLoadingNewFilter,
  } = useRankingsPageFilters({ isLoadingNewFilter: !data })
  const [watchingCollections, setWatchingCollections] = useState<string[]>([])
  const [hiddenCollections, setHiddenCollections] = useState<string[]>([])
  const [pinnedCollections, setPinnedCollections] = useState<{
    [slug: string]: boolean
  }>({})

  const [columnSortKey, setColumnSortKey] = useState<ColumnSortKey>(
    isTopTab ? "VOLUME" : "DEFAULT",
  )
  const [sortAscending, setSortAscending] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [showLoadingSkeletons, setShowLoadingSkeletons] = useState(false)
  const chainOptions = useChainOptions()

  // feature flag (should be removed eventually)
  const floorPricePercentChangeEnabled = useIsFloorPricePercentChangeEnabled()

  // determines whether API supports floorPricePercentChange query for this view
  const floorPricePercentChangeSupported = isFloorPricePercentChangeSupported(
    sortBy.value,
    category.value,
    isWatchlistTab,
  )
  const { visibleColumnSortKeys, columnSorts } = useColumnSorts(
    floorPricePercentChangeSupported && floorPricePercentChangeEnabled,
  )

  useEffect(() => {
    if (showLoadingSkeletons) {
      setShowLoadingSkeletons(isLoadingNext)
    }
  }, [isLoadingNext, showLoadingSkeletons])

  useUpdateEffect(() => {
    setWatchingCollections([])
    setHiddenCollections([])
  }, [currentTab])

  useUpdateEffect(() => {
    setColumnSortKey(isTopTab ? "VOLUME" : "DEFAULT")
    setSortAscending(false)
  }, [currentTab, category, chain, sortBy])

  useEffect(() => {
    trackRankingsPage({
      category: category.value,
      chain: chain.value,
      sortBy: isTrendingTab ? null : sortBy.value,
      columnSort: columnSortKey,
      sortAscending,
      currentTab,
      currentPage: isTrendingTab ? null : currentPage,
    })
    if (collections && !(isLoadingNewFilter || isLoadingNext)) {
      const count = getNodes(collections).length
      const maxCollections = getMaxNumberOfCollections(currentTab, sortBy.value)
      if (count < maxCollections && count >= 100 && hasNext) {
        loadNext(maxCollections - count)
      }
    }
  }, [
    currentTab,
    currentPage,
    category.value,
    chain.value,
    sortBy.value,
    sortAscending,
    columnSortKey,
    isTrendingTab,
    collections,
    isLoadingNewFilter,
    isLoadingNext,
    hasNext,
    loadNext,
    sortBy,
  ])

  const chainOptionsSmall = useMemo(
    () => getSmallChainOptions(chainOptions),
    [chainOptions],
  )
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const filtersRef = useRef<HTMLDivElement>(null)
  const isWidescreen = !useIsLessThanXl()

  const scrollToTop = useCallback(() => {
    if (isWidescreen) {
      const filtersOffsetTop = filtersRef.current?.offsetTop ?? 232
      const scrollYFilters = filtersOffsetTop - NAV_HEIGHT_PX
      if (window.scrollY > scrollYFilters) {
        window.scrollTo(0, scrollYFilters)
      }
      return
    }
    const tableOffsetTop = tableContainerRef.current?.offsetTop ?? 246
    const filtersHeaderHeight = filtersRef.current?.clientHeight ?? 67
    const tableOffset = tableOffsetTop - filtersHeaderHeight
    if (window.scrollY > tableOffset) {
      window.scrollTo(0, tableOffset)
    }
  }, [isWidescreen])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    scrollToTop()
  }

  const rows = useMemo(() => {
    // Unclear why this is an unnecessary condition, not guarding for this leads
    // to type error on getRows(rankings...) call below
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!data || !collections) {
      setIsLoadingNewFilter(true)
      return []
    }
    setIsLoadingNewFilter(false)
    return getRows(
      collections,
      watchlist ?? null,
      columnSortKey,
      sortAscending,
      currentPage,
    ).filter(row => !hiddenCollections.includes(row.slug))
  }, [
    data,
    collections,
    setIsLoadingNewFilter,
    watchlist,
    columnSortKey,
    sortAscending,
    currentPage,
    hiddenCollections,
  ])

  useUpdateEffect(() => {
    handlePageChange(0)
  }, [chain, category, sortBy, columnSortKey, sortAscending])

  const initialSortIndex = isTopTab ? 1 : undefined
  const emptyWatchlist = useMemo(
    () => rows.length === 0 && isWatchlistTab,
    [rows, isWatchlistTab],
  )

  const showNoResults =
    (noResults || emptyWatchlist) && !(isLoadingNewFilter || isLoadingNext)
  const hidePaginationArrows =
    getMaxNumberOfCollections(currentTab, sortBy.value) === 100 ||
    showNoResults ||
    (collections && getNodes(collections).length <= 100)
  const hideTimeOptions =
    ((noResults && isWatchlistTab) || emptyWatchlist) &&
    !((isLoadingNewFilter || isLoadingNext) && isWatchlistTab)

  const trackAndSetSortValues = useCallback(
    (newColumnSortValue: ColumnSortKey, newSortAscendingValue: boolean) => {
      const newValue = {
        columnSort: newColumnSortValue,
        sortAscending: newSortAscendingValue,
      }
      setColumnSortKey(newColumnSortValue)
      setSortAscending(newSortAscendingValue)
      trackSorting({
        category: category.value,
        chain: chain.value,
        sortBy: isTrendingTab ? null : sortBy.value,
        columnSort: columnSortKey,
        sortAscending,
        newValue,
        currentTab,
        currentPage: isTrendingTab ? null : currentPage,
      })
    },
    [
      category.value,
      chain.value,
      isTrendingTab,
      sortBy.value,
      columnSortKey,
      sortAscending,
      currentTab,
      currentPage,
    ],
  )

  const setSort = useCallback(
    (sortKey: ColumnSortKey, sortDirectionAscending: boolean) => {
      trackAndSetSortValues(sortKey, sortDirectionAscending)
      scrollToTop()
    },
    [trackAndSetSortValues, scrollToTop],
  )

  const sortableHeaders = useSortableHeaders(
    useMemo(
      () => [
        {
          header: (
            <StatsColumnHeader>
              {t("columnHeader", "Collection")}
            </StatsColumnHeader>
          ),
        },
        ...visibleColumnSortKeys.map(key => {
          const columnSort = columnSorts[key]
          const isSelectedColumnSort = columnSortKey === key
          const ColumnHeader = isSelectedColumnSort
            ? StatsSelectedColumnHeader
            : StatsClickableColumnHeader
          return {
            header: <ColumnHeader>{columnSort.desktopLabel}</ColumnHeader>,
            sortDirection:
              columnSortKey === key
                ? sortAscending
                  ? "asc"
                  : "desc"
                : undefined,
            sortIndicatorHideMode: "remove" as const,
            sort: (sortDirection?: SortDirection) => {
              setSort(key, sortDirection === "asc")
              setShowLoadingSkeletons(isLoadingNext)
            },
          }
        }),
      ],
      [
        t,
        visibleColumnSortKeys,
        columnSorts,
        columnSortKey,
        sortAscending,
        setSort,
        isLoadingNext,
      ],
    ),
    { index: initialSortIndex, direction: "desc" },
  )

  const trackAndSetValue = useCallback(
    (
      setValue: (value: SelectOption) => void,
      trackFilter: (params: RankingsPageFilterParams) => void,
    ) =>
      (value: SelectOption) => {
        setValue(value)
        const params = {
          path: window.location.pathname,
          category: category.value,
          chain: chain.value,
          sortBy: isTrendingTab ? null : sortBy.value,
          columnSort: columnSortKey,
          sortAscending,
          currentTab,
          currentPage: isTrendingTab ? null : currentPage,
          newValue: value.value,
        }
        trackFilter(params)
      },
    [
      category.value,
      chain.value,
      isTrendingTab,
      sortBy.value,
      columnSortKey,
      sortAscending,
      currentTab,
      currentPage,
    ],
  )

  const mobileSorts = useMemo(() => {
    return Object.entries(columnSorts).reduce<MobileSort[]>(
      (previous, current) => {
        const [sortKey, columnSort] = current
        if (columnSort.mobile.allowSortDescend) {
          const sorts = [false, true].map(sortAscending => ({
            sortAscending,
            label: t(
              "mobileSort.sortAscending.label",
              "{{sortAscendingQualifier}} {{columnSortMobileLabel}}",
              {
                columnSortMobileLabel: columnSort.mobile.label,
                sortAscendingQualifier: sortAscending ? "Lowest" : "Highest",
              },
              { forceString: true },
            ),
            sortKey,
            show: columnSort.mobile.show,
          }))
          return [...previous, ...sorts]
        }
        const sort = {
          label: t(
            "mobileSort.columnSort.label",
            "{{columnSortMobileLabel}}",
            {
              columnSortMobileLabel: columnSort.mobile.label,
            },
            { forceString: true },
          ),
          sortAscending: false,
          sortKey,
          show: columnSort.mobile.show,
        }
        return [...previous, sort]
      },
      [],
    )
  }, [t, columnSorts])

  const mobileSortRankingSelect = useMemo(() => {
    const mobileSortValue = mobileSorts.findIndex(
      sort =>
        sort.sortAscending === sortAscending && sort.sortKey === columnSortKey,
    )
    const mobileSort = mobileSorts[mobileSortValue]
    return (
      <MobileSortRankingSelect
        appendTo={tableContainerRef.current ?? undefined}
        options={mobileSorts
          .filter(sort => sort.show)
          .map((sort, index) => ({
            value: index.toString(),
            label: sort.label,
          }))}
        setValue={(selectOption: SelectOption) => {
          const mobileSort = mobileSorts[parseInt(selectOption.value)]
          setSort(mobileSort.sortKey, mobileSort.sortAscending)
        }}
        testId="Rankings--column-sort-small"
        value={{ value: mobileSortValue.toString(), label: mobileSort.label }}
      />
    )
  }, [sortAscending, columnSortKey, mobileSorts, setSort])

  const filters = useMemo(() => {
    return (
      <>
        <SmallFiltersContainer className="w-full">
          <NoScrollbarSpaceBetween className="w-full overflow-x-auto px-4 py-0 xl:p-0">
            <Flex paddingRight="4px">
              {showCategoryAndChainFilters && (
                <>
                  <RankingsPageSelect
                    appendTo={tableContainerRef.current ?? undefined}
                    options={categoryOptionsWithoutAvatar}
                    setValue={trackAndSetValue(
                      setCategory,
                      trackCategoryFilter,
                    )}
                    testId="Rankings--category-dropdown-small"
                    value={category}
                  />
                  <RankingsPageSelect
                    appendTo={tableContainerRef.current ?? undefined}
                    options={chainOptionsSmall}
                    setValue={trackAndSetValue(setChain, trackChainFilter)}
                    testId="Rankings--chain-dropdown-small"
                    value={chain}
                  />
                </>
              )}
              {!showNoResults && showMobileSort && mobileSortRankingSelect}
            </Flex>
            {!hideTimeOptions && (
              <RankingsPageSelect
                appendTo={tableContainerRef.current ?? undefined}
                options={
                  isTrendingTab ? trendingSortByTimeOptions : sortByTimeOptions
                }
                setValue={trackAndSetValue(setSortBy, trackSortByFilter)}
                testId="Rankings--sortBy-dropdown-small"
                useTabs
                value={sortBy}
              />
            )}
          </NoScrollbarSpaceBetween>
        </SmallFiltersContainer>
        <LargeFiltersContainer className="w-full">
          <SpaceBetween className="w-full overflow-x-auto px-4 py-0 xl:p-0">
            <Flex>
              {showCategoryAndChainFilters && (
                <>
                  <RankingsPageSelect
                    appendTo={tableContainerRef.current ?? undefined}
                    options={categoryOptionsWithoutAvatar}
                    setValue={trackAndSetValue(
                      setCategory,
                      trackCategoryFilter,
                    )}
                    testId="Rankings--category-dropdown"
                    value={category}
                  />
                  <RankingsPageSelect
                    appendTo={tableContainerRef.current ?? undefined}
                    includeTabLabelTooltip
                    options={chainOptions}
                    setValue={trackAndSetValue(setChain, trackChainFilter)}
                    testId="Rankings--chain-dropdown"
                    value={chain}
                  />
                </>
              )}
              {!showNoResults && showMobileSort && mobileSortRankingSelect}
            </Flex>
            {!hideTimeOptions && (
              <RankingsPageSelect
                appendTo={tableContainerRef.current ?? undefined}
                options={
                  isTrendingTab ? trendingSortByTimeOptions : sortByTimeOptions
                }
                setValue={trackAndSetValue(setSortBy, trackSortByFilter)}
                testId="Rankings--sortBy-dropdown"
                useTabs
                value={sortBy}
              />
            )}
          </SpaceBetween>
        </LargeFiltersContainer>
      </>
    )
  }, [
    category,
    chain,
    setCategory,
    setChain,
    setSortBy,
    sortBy,
    chainOptionsSmall,
    trackAndSetValue,
    showCategoryAndChainFilters,
    sortByTimeOptions,
    hideTimeOptions,
    mobileSortRankingSelect,
    showMobileSort,
    showNoResults,
    categoryOptionsWithoutAvatar,
    chainOptions,
    isTrendingTab,
    trendingSortByTimeOptions,
  ])

  const headerProps = {
    top: $nav_height,
    overflowX: "auto",
    overflowY: "visible",
    padding: { _: "8px", xl: "16px" },
    border: "0px none transparent !important",
  } as const

  const isLoading = useMemo(
    () => isLoadingNewFilter || showLoadingSkeletons,
    [isLoadingNewFilter, showLoadingSkeletons],
  )
  const tableContent = (
    <>
      {showNoResults ? (
        <>
          <FeatureTable.HeaderContainer
            className="w-full border-none p-0"
            ref={filtersRef}
            {...headerProps}
          >
            {filters}
          </FeatureTable.HeaderContainer>
          <RankingsTableNoItems
            message={
              emptyView ??
              t("noItems.message", "No items found for this search")
            }
          />
        </>
      ) : (
        <Block margin={{ _: "0 -16px", xl: "initial" }}>
          <StatsTable
            displayRankIndex={displayRankIndex}
            filters={filters}
            floorPricePercentChangeSupported={floorPricePercentChangeSupported}
            isLoading={isLoading}
            isWatchlistTab={isWatchlistTab}
            rows={rows}
            sortableHeaders={sortableHeaders}
          />
        </Block>
      )}
    </>
  )
  const chainLabel = chain.label !== "All chains" ? `${chain.label} ` : ""
  const hasMore = useMemo(
    () => collections && getNodes(collections).length > currentPage * PAGE_SIZE,
    [collections, currentPage],
  )

  return (
    <AnalyticsContextProvider
      eventParams={{
        chain: chain.value,
        category: category.value,
        sortBy: sortBy.value,
        columnSort: columnSortKey,
        sortAscending,
        currentTab,
        currentPage,
      }}
      eventSource={EVENT_SOURCE}
    >
      <RankingsWatchlistContext.Provider
        value={{
          watchingCollections,
          setWatchingCollections,
          hiddenCollections,
          setHiddenCollections,
          pinnedCollections,
          setPinnedCollections,
        }}
      >
        <RankingsPageLayout
          chainLabel={chainLabel}
          chainValue={chain.value}
          currentTab={currentTab}
          pagination={
            !hidePaginationArrows && (
              <PaginationArrows
                currentPage={currentPage}
                hasMore={hasMore}
                limit={
                  // We can't use hasNext because we preload the collections.
                  // If we've already loaded all of the collections, the limit is however many collections we have.
                  // If we haven't we'll use the max limit based on the time window selected
                  collections && getNodes(collections).length > 100
                    ? getNodes(collections).length
                    : getMaxNumberOfCollections(currentTab, sortBy.value)
                }
                loadNext={async () => {
                  setShowLoadingSkeletons(isLoadingNext)
                }}
                pageSize={PAGE_SIZE}
                setCurrentPage={handlePageChange}
              />
            )
          }
          tableContent={tableContent}
        />
      </RankingsWatchlistContext.Provider>
    </AnalyticsContextProvider>
  )
}

const StatsColumnHeader = styled(Text).attrs({
  size: "small",
})`
  color: inherit;
  white-space: nowrap;
`

const StatsClickableColumnHeader = styled(Text).attrs({
  size: "small",
})`
  color: ${props => props.theme.colors.text.secondary};
  white-space: nowrap;

  :hover {
    color: ${props => props.theme.colors.text.primary} !important;
  }
`

const StatsSelectedColumnHeader = styled(Text).attrs({
  size: "small",
})`
  color: ${props => props.theme.colors.text.primary};
  white-space: nowrap;
`

// TODO: Convert to not using styled components.
// Add new class in TailwindBase to hide scrollbar.
const NoScrollbarSpaceBetween = styled(SpaceBetween)`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const MobileSortRankingSelect = styled(RankingSelect)`
  ${media({
    lg: css`
      display: none;
    `,
  })}
`
const RankingsPageSelect = styled(RankingSelect)`
  margin: 0 4px;

  ${media({
    lg: css`
      margin: 6px 0px 10px 12px;
    `,
  })}
`

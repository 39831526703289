import React, { useContext, useEffect, useState } from "react"
import { Icon, Text, FlexColumn, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import { RankingsWatchlistContext } from "@/features/rankings/components/RankingsPage/RankingsPage.react"
import { useIsStatsPageCollectionHoverPreviewEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { StatsCollectionCell_collection$key } from "@/lib/graphql/__generated__/StatsCollectionCell_collection.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { CollectionAssetsPreviewTooltip } from "components/collections/CollectionAssetsPreviewTooltip.react"
import { PLACEHOLDER_IMAGE } from "../../../constants"

const IMAGE_SIZE_LARGE = 64
const IMAGE_SIZE_SMALL = 48

const COLLECTION_NAME_PADDING_LEFT_LARGE = 24
const COLLECTION_NAME_PADDING_LEFT_SMALL = 10

type StatsCollectionCellProps = {
  collection: StatsCollectionCell_collection$key | null
  subtitle?: JSX.Element
  isPinned: boolean
  isWatchlistTab: boolean
  priority: boolean
  collectionCellMaxWidth: number
}

export const StatsCollectionCell = ({
  collection: collectionKey,
  subtitle,
  isPinned,
  isWatchlistTab,
  priority,
  collectionCellMaxWidth,
}: StatsCollectionCellProps) => {
  const isHoverPreviewEnabled = useIsStatsPageCollectionHoverPreviewEnabled()
  const t = useTranslate("statsV2")
  const { pinnedCollections } = useContext(RankingsWatchlistContext)

  const collection = useFragment(
    graphql`
      fragment StatsCollectionCell_collection on CollectionType {
        name
        logo
        isVerified
        slug
      }
    `,
    collectionKey,
  )

  const slug = collection ? collection.slug : ""
  const hasSubtitle = subtitle !== undefined
  const [isCurrentlyPinned, setIsCurrentlyPinned] = useState(
    slug in pinnedCollections ? pinnedCollections[slug] : isPinned,
  )

  useEffect(() => {
    setIsCurrentlyPinned(
      slug in pinnedCollections ? pinnedCollections[slug] : isPinned,
    )
  }, [pinnedCollections, isPinned, slug])

  if (!collection) {
    return null
  }

  let maxWidth = 0
  if (hasSubtitle) {
    maxWidth =
      collectionCellMaxWidth -
      IMAGE_SIZE_SMALL -
      COLLECTION_NAME_PADDING_LEFT_SMALL
  } else {
    maxWidth =
      collectionCellMaxWidth -
      IMAGE_SIZE_LARGE -
      COLLECTION_NAME_PADDING_LEFT_LARGE
  }

  const fontSize = hasSubtitle ? "small" : "medium"
  const verifiedIconSize = hasSubtitle ? "tiny" : "small"
  const name = collection.name
  const isVerified = collection.isVerified
  const imageSize = hasSubtitle ? IMAGE_SIZE_SMALL : IMAGE_SIZE_LARGE

  const collectionName = (
    <StatsCollectionNameContainer>
      <Flex alignItems="center" className="w-full">
        <Text.Body
          className="m-0 text-primary"
          size={fontSize}
          style={{ maxWidth }}
          weight="semibold"
        >
          <Overflow breakWord lines={1}>
            {name}
          </Overflow>
        </Text.Body>
        {isVerified && (
          <VerificationIcon
            showTooltip={false}
            size={verifiedIconSize}
            verificationStatus="VERIFIED"
          />
        )}
      </Flex>
    </StatsCollectionNameContainer>
  )

  const pinIconRightOffset = imageSize === 64 ? 8 : 0
  const pinIcon = (
    <PinContainer style={{ left: imageSize - pinIconRightOffset }}>
      <PinIcon
        className="text-white dark:text-black"
        size={12}
        value="push_pin"
        variant="sharp"
      ></PinIcon>
    </PinContainer>
  )

  const image = (
    <StatsCollectionImageContainer
      style={{ height: imageSize, width: imageSize }}
    >
      <Image
        alt={t("stats.collectionImage", "Collection Image")}
        height={imageSize}
        layout="fill"
        objectFit="cover"
        priority={priority}
        sizes={`${imageSize}px`}
        src={collection.logo || PLACEHOLDER_IMAGE}
      />
    </StatsCollectionImageContainer>
  )

  return (
    <>
      {isHoverPreviewEnabled ? (
        <CollectionAssetsPreviewTooltip
          placement="top-start"
          slug={collection.slug}
          width="auto"
        >
          {image}
        </CollectionAssetsPreviewTooltip>
      ) : (
        image
      )}
      {isWatchlistTab && isCurrentlyPinned && pinIcon}
      <FlexColumn
        className={classNames(
          "w-full items-start self-center",
          hasSubtitle ? "pl-2.5" : "pl-6",
        )}
      >
        {collectionName}
        {hasSubtitle && subtitle}
      </FlexColumn>
    </>
  )
}

const PinContainer = styled.div`
  box-sizing: border-box;
  border-radius: 50%;

  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;

  background: ${props => props.theme.colors.text.primary};
  border: 1.5px solid ${props => props.theme.colors.base1};
  z-index: 99;
`

const PinIcon = styled(Icon)`
  position: absolute;
  top: 3px;
  left: 2px;

  transform: rotate(35deg);
  z-index: 99;
`

const StatsCollectionImageContainer = styled.div`
  flex: none;
  overflow: hidden;
  position: relative;

  border-width: 1px;
  border-style: solid;
  border-radius: ${props => props.theme.borderRadius.default};

  border-color: ${props => props.theme.colors.components.border.level1};
`

const StatsCollectionNameContainer = styled(Block)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`

import React from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { Button, ButtonProps } from "@/design-system/Button"

type EmptyBaseProps = {
  text: string
  buttonText: string
  buttonProps: ButtonProps
}

export const EmptyBase = ({
  text,
  buttonText,
  buttonProps,
}: EmptyBaseProps) => (
  <>
    <CenterAligned className="px-0 py-16 sm:hidden">
      <Text.Body
        className="pb-6 text-center text-secondary"
        size="medium"
        weight="semibold"
      >
        {text}
      </Text.Body>
      <Button {...buttonProps} size="small">
        {buttonText}
      </Button>
    </CenterAligned>
    <CenterAligned className="hidden px-0 py-16 sm:flex">
      <Text.Heading className="pb-6 text-center text-secondary" size="small">
        {text}
      </Text.Heading>
      <Button {...buttonProps}>{buttonText}</Button>
    </CenterAligned>
  </>
)

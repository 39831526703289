import React from "react"
import { formatISO, subDays } from "date-fns"
import { usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { getAppInitialProps } from "@/components/app/initialProps"
import { ErrorPage } from "@/components/pages/ErrorPage"
import { RankingsPageTop_data$key } from "@/lib/graphql/__generated__/RankingsPageTop_data.graphql"
import { RankingsPageTopPaginationQuery } from "@/lib/graphql/__generated__/RankingsPageTopPaginationQuery.graphql"
import { RankingsPageTopQuery } from "@/lib/graphql/__generated__/RankingsPageTopQuery.graphql"
import { isFetchError } from "@/lib/graphql/error"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { CATEGORY_SLUGS } from "../../../../constants"
import {
  PAGE_SIZE,
  SORT_BY_TO_TIME_WINDOW,
  QUERY_PARAM_TO_SORT,
  IS_NEW_CUTOFF_DAYS,
  DEFAULT_SORT_BY,
  getTopCollectionsSortBy,
  isFloorPricePercentChangeSupported,
  DEFAULT_CATEGORY,
} from "./constants"
import { RankingsPage } from "./RankingsPage.react"
import { TopRankingsPaginationFragment } from "./types"

type RankingsPageTopProps = {
  categorySlug?: string
}

export const RankingsPageTop: GraphQLNextPage<
  RankingsPageTopQuery,
  RankingsPageTopProps
> = ({ data: rankingsCollectionDataKey, error }) => {
  const paginationFragment = usePaginationFragment<
    RankingsPageTopPaginationQuery,
    RankingsPageTop_data$key
  >(
    /* eslint-disable relay/unused-fields, relay/must-colocate-fragment-spreads */
    graphql`
      fragment RankingsPageTop_data on Query
      @refetchable(queryName: "RankingsPageTopPaginationQuery") {
        topCollectionsByCategory(
          after: $cursor
          chains: $chain
          first: $count
          sortBy: $topCollectionsSortBy
          categories: $categories
        ) @connection(key: "RankingsPageTop_topCollectionsByCategory") {
          edges {
            node {
              createdDate
              name
              slug
              logo
              isVerified
              relayId
              ...StatsCollectionCell_collection
              ...collection_url
              statsV2 {
                totalQuantity
              }
              windowCollectionStats(statsTimeWindow: $timeWindow) {
                floorPrice {
                  unit
                  eth
                  symbol
                }
                numOwners
                totalSupply
                totalListed
                numOfSales
                volumeChange
                volume {
                  eth
                  unit
                  symbol
                }
              }
              floorPricePercentChange(statsTimeWindow: $timeWindow)
                @include(if: $floorPricePercentChange)
            }
          }
        }
      }
    `,
    /* eslint-enable relay/unused-fields, relay/must-colocate-fragment-spreads */
    rankingsCollectionDataKey,
  )

  if (error) {
    return <ErrorPage isFetchError={isFetchError(error)} statusCode={500} />
  }

  return (
    <RankingsPage
      collections={paginationFragment.data?.topCollectionsByCategory}
      currentTab="top"
      paginationFragment={paginationFragment as TopRankingsPaginationFragment}
    />
  )
}

const query = graphql`
  query RankingsPageTopQuery(
    $chain: [ChainScalar!]
    $count: Int!
    $cursor: String
    $topCollectionsSortBy: TrendingCollectionSort
    $categories: [CategoryV2Slug!]!
    $timeWindow: StatsTimeWindow
    $floorPricePercentChange: Boolean!
  ) {
    ...RankingsPageTop_data
  }
`

RankingsPageTop.getInitialProps = QP.nextParser(
  {
    category: QP.Optional(QP.CategorySlug),
    chain: QP.Optional(QP.ChainIdentifier),
    sortBy: QP.Optional(QP.string),
  },
  async (
    { category, chain, sortBy },
    ctx,
  ): Promise<
    GraphQLInitialProps<RankingsPageTopQuery, RankingsPageTopProps>
  > => {
    const sortByValue = sortBy || DEFAULT_SORT_BY
    const slug = [DEFAULT_CATEGORY]
      .concat(CATEGORY_SLUGS)
      .find(o => o === category && category !== "new")

    const variables = {
      chain: chain ? [chain] : undefined,
      count: PAGE_SIZE,
      createdAfter:
        category === "new"
          ? formatISO(subDays(new Date(), IS_NEW_CUTOFF_DAYS))
          : undefined,
      sortBy: QUERY_PARAM_TO_SORT.get(sortByValue),
      topCollectionsSortBy: getTopCollectionsSortBy(sortByValue),
      parents: slug ? [slug] : undefined,
      categories: category ? [category] : [],
      timeWindow: SORT_BY_TO_TIME_WINDOW.get(sortByValue),
      floorPricePercentChange: isFloorPricePercentChangeSupported(
        sortByValue,
        category,
      ),
    }

    const appInitialProps = await getAppInitialProps(ctx, {
      query,
      variables,
      cachePolicy: { maxAge: 30, revalidate: 60 },
    })

    return {
      ...appInitialProps,
      categorySlug: category,
      variables,
    }
  },
)

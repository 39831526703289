import React from "react"
import { SpaceBetween, Text } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { StickyBar } from "@/components/layout/StickyBar.react"
import { Block } from "@/design-system/Block"
import { FeatureTable } from "@/design-system/FeatureTable"
import { Flex } from "@/design-system/Flex"
import { useTranslate } from "@/hooks/useTranslate"
import { media } from "@/styles/styleUtils"
import { $nav_height } from "@/styles/variables"

type StatsHeaderProps = {
  displayRankIndex?: boolean
  filters: JSX.Element
  sortableHeaders: JSX.Element[]
  isWatchlistTab: boolean
}

export const StatsTableHeader = ({
  displayRankIndex = true,
  filters,
  sortableHeaders,
  isWatchlistTab,
}: StatsHeaderProps) => {
  const t = useTranslate("statsV2")

  return (
    <>
      <Block
        paddingBottom={{ _: "20px", lg: "0" }}
        paddingTop={{ _: "6px", lg: "0" }}
      >
        {filters}
      </Block>
      <DesktopStickyBar fullWidth={false} height={38} shadowVariant="border">
        <SpaceBetween
          className="sticky z-[1] h-full w-full items-center overflow-hidden border-none"
          style={{ top: $nav_height }}
        >
          {sortableHeaders.map((header, index) => (
            <ResponsiveFeatureTableCell
              color={index === 0 ? "text.secondary" : undefined}
              hideAtXXLBreakpoint={index > 4}
              justifyContent={index === 0 ? "flex-start" : "flex-end"}
              key={index}
              paddingLeft={
                index === 0
                  ? displayRankIndex
                    ? { _: "66px", xl: "20px" }
                    : { _: "14px", xl: "8px" }
                  : "0px"
              }
              width={
                index === 0
                  ? { _: "462px", xl: "492px" }
                  : header.props.header.props.children === "Sales"
                  ? "88px"
                  : undefined
              }
            >
              {index === 0 && displayRankIndex && (
                <Block marginRight="32px">
                  <Text.Body className="text-secondary" size="small">
                    #
                  </Text.Body>
                </Block>
              )}
              {header}
            </ResponsiveFeatureTableCell>
          ))}
          <Flex alignItems="center" paddingRight={{ _: "8px", xl: "0px" }}>
            <Block width="44px"></Block>
          </Flex>
        </SpaceBetween>
      </DesktopStickyBar>
      <MobileStickyBar fullWidth={false} height={24} shadowVariant="border">
        <StatsHeaderMobileContainer>
          <FeatureTable.Cell justifyContent="flex-start">
            {displayRankIndex && (
              <Block marginRight="16px">
                <Text.Body className="text-secondary" size="tiny">
                  #
                </Text.Body>
              </Block>
            )}
            <Text.Body className="text-secondary" size="tiny">
              {t("stats.columnHeader.collection", "Collection")}
            </Text.Body>
          </FeatureTable.Cell>
          <FeatureTable.Cell
            justifyContent="flex-end"
            paddingRight={
              isWatchlistTab
                ? "16px"
                : {
                    _: "2px",
                    sm: "28px",
                    md: "50px",
                  }
            }
          >
            <Text.Body className="text-secondary" size="tiny">
              {t("stats.columnHeader.volume", "Volume")}
            </Text.Body>
          </FeatureTable.Cell>
        </StatsHeaderMobileContainer>
      </MobileStickyBar>
    </>
  )
}
const ResponsiveFeatureTableCell = styled(FeatureTable.Cell)<{
  hideAtXXLBreakpoint: boolean
}>`
  ${props =>
    props.hideAtXXLBreakpoint &&
    `
    display: none;
  `}
  ${media({
    xxl: css`
      display: flex;
    `,
  })}
`

const DesktopStickyBar = styled(StickyBar)`
  display: none;
  ${media({
    lg: css`
      display: flex;
    `,
  })}
`

const MobileStickyBar = styled(StickyBar)`
  ${media({
    lg: css`
      display: none;
    `,
  })}
`

const StatsHeaderMobileContainer = styled(FeatureTable.HeaderContainer)`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border: 0px none transparent !important;
`

import React from "react"
import { usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { getAppInitialProps } from "@/components/app/initialProps"
import { RankingsPageTrending_data$key } from "@/lib/graphql/__generated__/RankingsPageTrending_data.graphql"
import { RankingsPageTrendingPaginationQuery } from "@/lib/graphql/__generated__/RankingsPageTrendingPaginationQuery.graphql"
import { RankingsPageTrendingQuery } from "@/lib/graphql/__generated__/RankingsPageTrendingQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import {
  DEFAULT_SORT_BY,
  getTrendingCollectionsSortBy,
  isFloorPricePercentChangeSupported,
  PAGE_SIZE,
  SORT_BY_TO_TIME_WINDOW,
} from "./constants"
import { RankingsPage } from "./RankingsPage.react"
import { TrendingRankingsPaginationFragment } from "./types"
import { ELIGIBLE_TRENDING_COLLECTIONS } from "./utils"

type RankingsPageTrendingProps = {
  categorySlug?: string
}

export const RankingsPageTrending: GraphQLNextPage<
  RankingsPageTrendingQuery,
  RankingsPageTrendingProps
> = ({ data: rankingsCollectionDataKey }) => {
  const paginationFragment = usePaginationFragment<
    RankingsPageTrendingPaginationQuery,
    RankingsPageTrending_data$key
  >(
    /* eslint-disable relay/unused-fields, relay/must-colocate-fragment-spreads */
    graphql`
      fragment RankingsPageTrending_data on Query
      @refetchable(queryName: "RankingsPageTrendingPaginationQuery") {
        trendingCollectionsByCategory(
          after: $cursor
          chains: $chain
          first: $count
          sortBy: $trendingCollectionsSortBy
          categories: $categories
          topCollectionLimit: $eligibleCount
        )
          @connection(
            key: "RankingsPageTrending_trendingCollectionsByCategory"
          ) {
          edges {
            node {
              createdDate
              name
              slug
              logo
              isVerified
              relayId
              ...StatsCollectionCell_collection
              ...collection_url
              statsV2 {
                totalQuantity
              }
              windowCollectionStats(statsTimeWindow: $timeWindow) {
                floorPrice {
                  unit
                  eth
                  symbol
                }
                numOwners
                totalSupply
                totalListed
                numOfSales
                volumeChange
                volume {
                  unit
                  eth
                  symbol
                }
              }
              floorPricePercentChange(statsTimeWindow: $timeWindow)
                @include(if: $floorPricePercentChange)
            }
          }
        }
      }
    `,
    /* eslint-enable relay/unused-fields, relay/must-colocate-fragment-spreads */
    rankingsCollectionDataKey,
  )

  return (
    <RankingsPage
      collections={
        paginationFragment.data?.trendingCollectionsByCategory || undefined
      }
      currentTab="trending"
      paginationFragment={
        paginationFragment as TrendingRankingsPaginationFragment
      }
    />
  )
}

const query = graphql`
  query RankingsPageTrendingQuery(
    $chain: [ChainScalar!]
    $count: Int!
    $cursor: String
    $categories: [CategoryV2Slug!]!
    $eligibleCount: Int!
    $trendingCollectionsSortBy: TrendingCollectionSort
    $timeWindow: StatsTimeWindow
    $floorPricePercentChange: Boolean!
  ) {
    ...RankingsPageTrending_data
  }
`

RankingsPageTrending.getInitialProps = QP.nextParser(
  {
    category: QP.Optional(QP.CategorySlug),
    chain: QP.Optional(QP.ChainIdentifier),
    sortBy: QP.Optional(QP.string),
  },
  async (
    { category, chain, sortBy },
    ctx,
  ): Promise<
    GraphQLInitialProps<RankingsPageTrendingQuery, RankingsPageTrendingProps>
  > => {
    const sortByValue = sortBy || DEFAULT_SORT_BY
    const variables = {
      chain: chain ? [chain] : undefined,
      count: PAGE_SIZE,
      categories: category ? [category] : [],
      trendingCollectionsSortBy: getTrendingCollectionsSortBy(
        sortByValue,
        category,
      ),
      eligibleCount: ELIGIBLE_TRENDING_COLLECTIONS,
      timeWindow: SORT_BY_TO_TIME_WINDOW.get(sortByValue),
      floorPricePercentChange: isFloorPricePercentChangeSupported(
        sortByValue,
        category,
      ),
    }
    const appInitialProps = await getAppInitialProps(ctx, {
      query,
      variables,
      cachePolicy: { maxAge: 30, revalidate: 60 },
    })

    return {
      ...appInitialProps,
      variables,
    }
  },
)

import React from "react"
import { Text, FlexColumn, classNames } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { RankingsRow } from "@/features/rankings/components/RankingsPage/types"
import {
  formatCollectionValueChange,
  formatCollectionFloorPrice,
  formatCollectionVolume,
} from "@/features/rankings/components/RankingsPage/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { StatsWatchlistButton_data$key } from "@/lib/graphql/__generated__/StatsWatchlistButton_data.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"
import { STATS_EMPTY_PRICE_DISPLAY } from "../../../../constants"
import { StatsCollectionCell } from "../StatsCollectionCell.react"
import { StatsTablePrice } from "../StatsTablePrice"
import { StatsWatchlistButton } from "../StatsWatchlistButton"

const NUM_COLLECTIONS_ABOVE_FOLD = 10

type RankingsTableFullRowProps = {
  data: RankingsRow
  displayRankIndex?: boolean
  watchlistData: StatsWatchlistButton_data$key | null
  isWatchlistTab: boolean
  isPinned: boolean
  collectionCellMaxWidth: number
}

export const StatsTableRowPrimary = ({
  data,
  displayRankIndex = true,
  watchlistData,
  isWatchlistTab,
  isPinned,
  collectionCellMaxWidth,
}: RankingsTableFullRowProps) => {
  const t = useTranslate("statsV2")
  const { windowCollectionStats: stats, relayId, slug } = data

  const volume = formatCollectionVolume(bn(stats.volume.unit))
  const volumeSymbol = stats.volume.symbol

  const volumeChange = bn(stats.volumeChange)
  const volumeChangeString = formatCollectionValueChange(
    volumeChange.times(100),
  )
  const isVolumeChangePositive = volumeChange.isGreaterThan(0)

  const collectionRank = data.index + 1
  const collectionRankWidth =
    collectionRank < 101 ? "24px" : collectionRank < 901 ? "28px" : "30px"

  const floorPrice = stats.floorPrice?.unit
    ? formatCollectionFloorPrice(stats.floorPrice.unit)
    : undefined
  const floorPriceSymbol = stats.floorPrice?.symbol
  const hasFloorPrice = !(floorPrice === undefined || floorPrice === "0")

  const floorPriceTableCell = (
    <Flex alignItems="center" display="block">
      <Text.Body className="pr-1 text-secondary" size="small">
        <Overflow lines={1}>
          {hasFloorPrice
            ? t("stats.floorPrice", "Floor price:")
            : t("stats.noFloorPrice", "No floor price")}
        </Overflow>
      </Text.Body>
      {hasFloorPrice && (
        <StatsTablePrice
          size="small"
          subtleText
          symbol={floorPriceSymbol}
          value={floorPrice}
        />
      )}
    </Flex>
  )

  const collectionTableCell = (
    <Flex alignItems="center" className="w-full" paddingRight="8px">
      {displayRankIndex && (
        <Block minWidth={collectionRankWidth}>
          <Text.Body className="text-secondary" size="tiny" weight="semibold">
            <Overflow>{collectionRank}</Overflow>
          </Text.Body>
        </Block>
      )}
      <StatsCollectionCell
        collection={data}
        collectionCellMaxWidth={collectionCellMaxWidth}
        isPinned={isPinned}
        isWatchlistTab={isWatchlistTab}
        priority={data.index < NUM_COLLECTIONS_ABOVE_FOLD}
        subtitle={floorPriceTableCell}
      />
    </Flex>
  )

  const volumeTableCell = (
    <StatsTablePrice size="small" symbol={volumeSymbol} value={volume} />
  )

  const volumeChangeTableCell = volumeChange.isEqualTo(0) ? (
    <Text.Body className="text-primary" size="small" weight="semibold">
      {STATS_EMPTY_PRICE_DISPLAY}
    </Text.Body>
  ) : (
    <Text.Body
      color={isVolumeChangePositive ? "success" : "red-3"}
      size="small"
      weight="semibold"
    >
      {`${isVolumeChangePositive ? "+" : ""}${volumeChangeString}%`}
    </Text.Body>
  )

  const volumeStackedTableCell = (
    <FlexColumn
      className={classNames(
        "w-auto items-end self-center",
        isWatchlistTab ? "pr-1.5" : "pr-[18px]",
      )}
    >
      <Flex textAlign="right">{volumeTableCell}</Flex>
      <Flex textAlign="right">{volumeChangeTableCell}</Flex>
    </FlexColumn>
  )

  const WatchlistButtonContainer = isWatchlistTab
    ? PinningButtonContainer
    : FlexButtonContainer

  const watchlistButton = (
    <WatchlistButtonContainer>
      <StatsWatchlistButton
        collectionId={relayId}
        data={watchlistData}
        isWatchlistTab={isWatchlistTab}
        slug={slug}
      />
    </WatchlistButtonContainer>
  )
  return (
    <>
      {collectionTableCell}
      {volumeStackedTableCell}
      {watchlistButton}
    </>
  )
}

const PinningButtonContainer = styled(Flex)`
  align-items: center;
`

const FlexButtonContainer = styled(PinningButtonContainer)`
  display: none;
  ${media({
    sm: css`
      display: flex;
    `,
    lg: css`
      display: none;
    `,
  })}
`

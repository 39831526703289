import React from "react"
import { useTranslate } from "@/hooks/useTranslate"
import { EmptyBase } from "./EmptyBase.react"

const EXPLORE_COLLECTIONS_HREF = "/rankings"

export const EmptyWatchlist = () => {
  const t = useTranslate("rankings")
  const TEXT = t(
    "emptyWatchlist.text",
    "Collections you add to your watchlist will appear here.",
  )
  const BUTTON_TEXT = t("emptyWatchlist.buttonCTA", "Explore collections")
  return (
    <EmptyBase
      buttonProps={{
        href: EXPLORE_COLLECTIONS_HREF,
      }}
      buttonText={BUTTON_TEXT}
      text={TEXT}
    />
  )
}

import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  RefObject,
} from "react"
import { Icon, UnstyledButton, breakpoints } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { ConnectionHandler } from "relay-runtime"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { StarIcon } from "@/components/svgs/StarIcon.react"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Dropdown } from "@/design-system/Dropdown"
import { Flex } from "@/design-system/Flex"
import { Tooltip } from "@/design-system/Tooltip"
import { RankingsWatchlistContext } from "@/features/rankings/components/RankingsPage/RankingsPage.react"
import { isCollectionPinned } from "@/features/rankings/components/RankingsPage/utils"
import { WATCHLIST_PATH } from "@/features/rankings/constants"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackAddToWatchlist,
  trackRemoveFromWatchlist,
} from "@/lib/analytics/events/watchlistEvents"
import { StatsWatchlistButton_data$key } from "@/lib/graphql/__generated__/StatsWatchlistButton_data.graphql"
import { StatsWatchlistButtonMutation } from "@/lib/graphql/__generated__/StatsWatchlistButtonMutation.graphql"
import { StatsWatchlistButtonPinMutation } from "@/lib/graphql/__generated__/StatsWatchlistButtonPinMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

type Props = {
  data: StatsWatchlistButton_data$key | null
  slug: string
  collectionId: string
  isWatchlistTab: boolean
}

const useClickAway = (
  ref: RefObject<HTMLElement | null>,
  onClickAway: (event: MouseEvent) => void,
) => {
  const savedCallback = useRef(onClickAway)
  useEffect(() => {
    savedCallback.current = onClickAway
  }, [onClickAway])

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const { current: el } = ref
      if (
        el &&
        event.target instanceof HTMLElement &&
        !el.contains(event.target)
      ) {
        savedCallback.current(event)
      }
    }
    document.addEventListener("click", handler, true)

    return () => {
      document.removeEventListener("click", handler, true)
    }
  }, [ref])
}

export const StatsWatchlistButton = ({
  data: dataKey,
  slug,
  collectionId,
  isWatchlistTab,
}: Props) => {
  const t = useTranslate("statsV2")
  const {
    setWatchingCollections,
    watchingCollections,
    setHiddenCollections,
    hiddenCollections,
    setPinnedCollections,
    pinnedCollections,
  } = useContext(RankingsWatchlistContext)

  const data = useFragment(
    graphql`
      fragment StatsWatchlistButton_data on CollectionType {
        isWatching
        pinnedDate
      }
    `,
    dataKey,
  )

  const ref = useRef(null)
  const popperShowingRef = useRef(false)

  useClickAway(ref, event => {
    if (popperShowingRef.current) {
      event.preventDefault()
      event.stopImmediatePropagation()
      // This is a heuristic we make that the popper should have been closed at this point
      popperShowingRef.current = false
    }
  })

  useEffect(() => {
    setIsCurrentlyWatching(
      !!data?.isWatching || watchingCollections.includes(slug),
    )
  }, [data?.isWatching, watchingCollections, slug])

  const [isCurrentlyWatching, setIsCurrentlyWatching] = useState(
    watchingCollections.includes(slug),
  )
  const [isPinned, setIsPinned] = useState(
    slug in pinnedCollections
      ? pinnedCollections[slug]
      : isCollectionPinned(data?.pinnedDate),
  )
  useEffect(() => {
    setIsPinned(
      slug in pinnedCollections
        ? pinnedCollections[slug]
        : isCollectionPinned(data?.pinnedDate),
    )
  }, [pinnedCollections, isPinned, slug, data?.pinnedDate])

  const activeAccount = useActiveAccount()
  const { mutate } = useGraphQL()
  const { handleUnconnectedWallet } = useWalletModal()
  const { attempt, showSuccessMessage } = useToasts()

  const toggleWatchlistAuthenticated = () =>
    attempt(async () => {
      const { collections } = await mutate<StatsWatchlistButtonMutation>(
        graphql`
          mutation StatsWatchlistButtonMutation(
            $collectionId: CollectionRelayID!
            $isWatching: Boolean!
          ) {
            collections {
              updateWatchlist(
                collection: $collectionId
                isWatching: $isWatching
              )
            }
          }
        `,
        {
          collectionId,
          isWatching: !isCurrentlyWatching,
        },
        {
          shouldAuthenticate: true,
          updater: store => {
            if (!activeAccount?.user || collectionId === "") {
              return
            }

            // Leverage useContext for keeping stars up to date
            if (!isCurrentlyWatching) {
              setWatchingCollections(watchingCollections.concat(slug))
            } else {
              if (isWatchlistTab) {
                setHiddenCollections(hiddenCollections.concat(slug))
              }
              setWatchingCollections(
                watchingCollections.filter(s => s !== slug),
              )
            }

            // Add or remove collection from collectionWatchlist store
            const collection = store.get(collectionId)
            const connectionID = ConnectionHandler.getConnectionID(
              activeAccount.user.relayId,
              "StatsTable_collectionWatchlist",
            )
            const collectionWatchlist = store.get(connectionID)
            if (collectionWatchlist && collection) {
              // Updating isWatching on collection
              collection.setValue(!isCurrentlyWatching, "isWatching")
              if (!isCurrentlyWatching) {
                // Create new edge for collection
                const newEdge = ConnectionHandler.createEdge(
                  store,
                  collectionWatchlist,
                  collection,
                  "CollectionTypeEdge",
                )
                ConnectionHandler.insertEdgeAfter(collectionWatchlist, newEdge)
              } else {
                ConnectionHandler.deleteNode(collectionWatchlist, collectionId)
              }
            }
          },
        },
      )

      if (collections.updateWatchlist) {
        if (!isCurrentlyWatching) {
          trackAddToWatchlist({ collectionSlug: slug, isStatsPage: true })
        } else {
          trackRemoveFromWatchlist({
            collectionSlug: slug,
            isStatsPage: true,
          })
        }

        showSuccessMessage(
          <Flex>
            {isCurrentlyWatching
              ? t("watchlist.success.status.removed", "Removed from watchlist")
              : t("watchlist.success.status.added", "Added to watchlist")}
            {!isWatchlistTab && (
              <Block marginLeft="8px">
                <Link href={WATCHLIST_PATH}>
                  {t("watchlist.success.viewCTA", "View")}
                </Link>
              </Block>
            )}
          </Flex>,
        )
        setIsCurrentlyWatching(!isCurrentlyWatching)
      }
    })

  const toggleWatchlist = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault()
    event.stopPropagation()

    handleUnconnectedWallet(toggleWatchlistAuthenticated)
  }

  const togglePin = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault()
    event.stopPropagation()

    await attempt(async () => {
      const { collections } = await mutate<StatsWatchlistButtonPinMutation>(
        graphql`
          mutation StatsWatchlistButtonPinMutation(
            $collectionId: CollectionRelayID!
            $isPinned: Boolean!
          ) {
            collections {
              updateWatchlistPin(collection: $collectionId, isPinned: $isPinned)
            }
          }
        `,
        {
          collectionId,
          isPinned: !isPinned,
        },
        {
          shouldAuthenticate: true,
          updater: store => {
            if (collectionId) {
              // Update source of truth
              const newPinnedCollections = pinnedCollections
              newPinnedCollections[slug] = !isPinned
              setPinnedCollections(newPinnedCollections)

              const collection = store.get(collectionId)
              const pinnedDate = new Date()
              if (collection) {
                // Update pinnedDate on collection
                collection.setValue(
                  isPinned
                    ? new Date(9999, 12, 31).getTime() / 1000
                    : pinnedDate.getTime() / 1000,
                  "pinnedDate",
                )
              }
            }
          },
        },
      )

      if (collections.updateWatchlistPin) {
        setIsPinned(!isPinned)
      }
    })
  }

  if (!isWatchlistTab) {
    return (
      <Tooltip
        content={
          isCurrentlyWatching
            ? t("watchlist.tooltip.watching", "Watching")
            : t("watchlist.tooltip.notWatching", "Add to watchlist")
        }
        hideOnClick
      >
        <StatsButtonContainer
          aria-label={
            isCurrentlyWatching
              ? t("watchlist.button.watching", "Watching")
              : t("watchlist.button.notWatching", "Add to watchlist")
          }
          as="div"
          data-testid="rankings-watchlist-button"
          role="button"
          tabIndex={0}
          onClick={toggleWatchlist}
        >
          <StatsStarIcon active={isCurrentlyWatching} />
        </StatsButtonContainer>
      </Tooltip>
    )
  }

  return (
    <span ref={ref}>
      <Dropdown
        content={({ close, List, Item }) => (
          <List>
            <Item
              onClick={event => {
                close()
                togglePin(event)
              }}
            >
              <Item.Avatar>
                <PinIcon
                  className="text-primary"
                  size={24}
                  value="push_pin"
                ></PinIcon>
              </Item.Avatar>
              <Item.Content>
                <Item.Title>
                  {isPinned
                    ? t("watchlist.button.unpin", "Unpin collection")
                    : t("watchlist.button.pin", "Pin collection")}
                </Item.Title>
              </Item.Content>
            </Item>
            <Item
              onClick={event => {
                close()
                toggleWatchlist(event)
              }}
            >
              <Item.Avatar>
                <Icon size={24} value="delete"></Icon>
              </Item.Avatar>
              <Item.Content>
                <Item.Title>
                  {t("watchlist.button.remove", "Remove from watchlist")}
                </Item.Title>
              </Item.Content>
            </Item>
          </List>
        )}
        onTrigger={(_, event) => {
          popperShowingRef.current = true
          event.preventDefault()
          event.stopPropagation()
        }}
        onUntrigger={(_, event) => {
          popperShowingRef.current = false
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <StatsButtonContainer as="div" role="button" tabIndex={0}>
          <StatsMoreIcon size={24} value="more_vert" />
        </StatsButtonContainer>
      </Dropdown>
    </span>
  )
}

const PinIcon = styled(Icon)`
  transform: rotate(35deg);
`

const StatsButtonContainer = styled(UnstyledButton)`
  background-color: transparent;
  border-radius: 0px;
  z-index: 2;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-left: -14px;
  margin-right: -8px;

  @media (min-width: ${breakpoints.md}px) {
    margin-left: 0;
    margin-right: 0;
  }

  :hover:not([disabled]),
  :active:not([disabled]):active:not([disabled]),
  :focus:not([disabled]):focus:not([disabled]) {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
`

const StatsMoreIcon = styled(Icon)`
  transition: color 0.25s ease-in-out;
  color: ${props => props.theme.colors.text.primary};

  :hover {
    color: ${props => props.theme.colors.text.secondary};
  }

  :active {
    color: ${props => props.theme.colors.text.secondary};
  }
`

const StatsStarIcon = styled(StarIcon)`
  width: 24px;
  height: 24px;
  transition: color 0.25s ease-in-out;
  color: ${props => props.theme.colors.text.primary};

  :hover {
    color: ${props => props.theme.colors.text.secondary};
  }

  :active {
    color: ${props => props.theme.colors.text.secondary};
  }
`

/**
 * @generated SignedSource<<07c0944ec6ed6d036012a1eca34e7ac6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RankingsPageWatchlist_data$data = {
  readonly getAccount: {
    readonly user: {
      readonly collectionWatchlist: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly createdDate: string;
            readonly floorPricePercentChange?: number | null;
            readonly isVerified: boolean;
            readonly logo: string | null;
            readonly name: string;
            readonly pinnedDate: number;
            readonly relayId: string;
            readonly slug: string;
            readonly statsV2: {
              readonly totalQuantity: string;
            };
            readonly windowCollectionStats: {
              readonly floorPrice: {
                readonly eth: string;
                readonly symbol: string;
                readonly unit: string;
              } | null;
              readonly numOfSales: string;
              readonly numOwners: number;
              readonly totalListed: number;
              readonly totalSupply: number;
              readonly volume: {
                readonly eth: string;
                readonly symbol: string;
                readonly unit: string;
              };
              readonly volumeChange: number;
            };
            readonly " $fragmentSpreads": FragmentRefs<"StatsCollectionCell_collection" | "StatsWatchlistButton_data" | "collection_url">;
          } | null;
        } | null>;
      };
    } | null;
  };
  readonly " $fragmentType": "RankingsPageWatchlist_data";
};
export type RankingsPageWatchlist_data$key = {
  readonly " $data"?: RankingsPageWatchlist_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"RankingsPageWatchlist_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "getAccount",
  "user",
  "collectionWatchlist"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "statsTimeWindow",
    "variableName": "timeWindow"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "eth",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categories"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chains"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "floorPricePercentChange"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortAscending"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timeWindow"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./RankingsPageWatchlistPaginationQuery.graphql')
    }
  },
  "name": "RankingsPageWatchlist_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "identity"
        }
      ],
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "getAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserType",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": "collectionWatchlist",
              "args": [
                {
                  "kind": "Variable",
                  "name": "categories",
                  "variableName": "categories"
                },
                {
                  "kind": "Variable",
                  "name": "chains",
                  "variableName": "chains"
                },
                {
                  "kind": "Variable",
                  "name": "sortAscending",
                  "variableName": "sortAscending"
                },
                {
                  "kind": "Variable",
                  "name": "sortBy",
                  "variableName": "sortBy"
                }
              ],
              "concreteType": "CollectionTypeConnection",
              "kind": "LinkedField",
              "name": "__RankingsPageWatchlist_collectionWatchlist_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CollectionTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CollectionType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createdDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "logo",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isVerified",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "relayId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "pinnedDate",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "StatsCollectionCell_collection"
                        },
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "collection_url",
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isCategory",
                              "storageKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "StatsWatchlistButton_data"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CollectionStatsV2Type",
                          "kind": "LinkedField",
                          "name": "statsV2",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "totalQuantity",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": (v2/*: any*/),
                          "concreteType": "WindowCollectionStatsType",
                          "kind": "LinkedField",
                          "name": "windowCollectionStats",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PriceType",
                              "kind": "LinkedField",
                              "name": "floorPrice",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "numOwners",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "totalSupply",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "totalListed",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "numOfSales",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "volumeChange",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PriceType",
                              "kind": "LinkedField",
                              "name": "volume",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "condition": "floorPricePercentChange",
                          "kind": "Condition",
                          "passingValue": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": (v2/*: any*/),
                              "kind": "ScalarField",
                              "name": "floorPricePercentChange",
                              "storageKey": null
                            }
                          ]
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e133adb961b6d98f458a5a4d9e4849aa";

export default node;

/**
 * @generated SignedSource<<0e3ffed359863114c295f01c3bd0ba40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RankingsPageTrending_data$data = {
  readonly trendingCollectionsByCategory: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdDate: string;
        readonly floorPricePercentChange?: number | null;
        readonly isVerified: boolean;
        readonly logo: string | null;
        readonly name: string;
        readonly relayId: string;
        readonly slug: string;
        readonly statsV2: {
          readonly totalQuantity: string;
        };
        readonly windowCollectionStats: {
          readonly floorPrice: {
            readonly eth: string;
            readonly symbol: string;
            readonly unit: string;
          } | null;
          readonly numOfSales: string;
          readonly numOwners: number;
          readonly totalListed: number;
          readonly totalSupply: number;
          readonly volume: {
            readonly eth: string;
            readonly symbol: string;
            readonly unit: string;
          };
          readonly volumeChange: number;
        };
        readonly " $fragmentSpreads": FragmentRefs<"StatsCollectionCell_collection" | "collection_url">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "RankingsPageTrending_data";
};
export type RankingsPageTrending_data$key = {
  readonly " $data"?: RankingsPageTrending_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"RankingsPageTrending_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "trendingCollectionsByCategory"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "statsTimeWindow",
    "variableName": "timeWindow"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "eth",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "categories"
    },
    {
      "kind": "RootArgument",
      "name": "chain"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "eligibleCount"
    },
    {
      "kind": "RootArgument",
      "name": "floorPricePercentChange"
    },
    {
      "kind": "RootArgument",
      "name": "timeWindow"
    },
    {
      "kind": "RootArgument",
      "name": "trendingCollectionsSortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./RankingsPageTrendingPaginationQuery.graphql')
    }
  },
  "name": "RankingsPageTrending_data",
  "selections": [
    {
      "alias": "trendingCollectionsByCategory",
      "args": [
        {
          "kind": "Variable",
          "name": "categories",
          "variableName": "categories"
        },
        {
          "kind": "Variable",
          "name": "chains",
          "variableName": "chain"
        },
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "trendingCollectionsSortBy"
        },
        {
          "kind": "Variable",
          "name": "topCollectionLimit",
          "variableName": "eligibleCount"
        }
      ],
      "concreteType": "CollectionTypeConnection",
      "kind": "LinkedField",
      "name": "__RankingsPageTrending_trendingCollectionsByCategory_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "logo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isVerified",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "relayId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StatsCollectionCell_collection"
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "collection_url",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCategory",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CollectionStatsV2Type",
                  "kind": "LinkedField",
                  "name": "statsV2",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalQuantity",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v2/*: any*/),
                  "concreteType": "WindowCollectionStatsType",
                  "kind": "LinkedField",
                  "name": "windowCollectionStats",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PriceType",
                      "kind": "LinkedField",
                      "name": "floorPrice",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "numOwners",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalSupply",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalListed",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "numOfSales",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "volumeChange",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PriceType",
                      "kind": "LinkedField",
                      "name": "volume",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "floorPricePercentChange",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": (v2/*: any*/),
                      "kind": "ScalarField",
                      "name": "floorPricePercentChange",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9055dd8eb11c1ea8d650c681a7c0f2bc";

export default node;

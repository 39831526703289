/**
 * @generated SignedSource<<2ea9aac05a77b8a82f05870f8ad43d24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionWatchlistSort = "CREATED_DATE" | "FLOOR_PRICE" | "NUM_OWNERS" | "ONE_DAY_CHANGE" | "SEVEN_DAY_CHANGE" | "SEVEN_DAY_VOLUME" | "TOTAL_SUPPLY" | "%future added value";
export type StatsTableQuery$variables = {
  count: number;
  cursor?: string | null;
  identity: string;
  sortAscending?: boolean | null;
  sortBy?: CollectionWatchlistSort | null;
};
export type StatsTableQuery$data = {
  readonly getAccount: {
    readonly user: {
      readonly collectionWatchlist: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly pinnedDate: number;
            readonly slug: string;
            readonly " $fragmentSpreads": FragmentRefs<"StatsWatchlistButton_data">;
          } | null;
        } | null>;
      };
    } | null;
  };
};
export type StatsTableQuery = {
  response: StatsTableQuery$data;
  variables: StatsTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identity"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortAscending"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v5 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "identity"
  }
],
v6 = {
  "kind": "Variable",
  "name": "sortAscending",
  "variableName": "sortAscending"
},
v7 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinnedDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StatsTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "collectionWatchlist",
                "args": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "concreteType": "CollectionTypeConnection",
                "kind": "LinkedField",
                "name": "__StatsTable_collectionWatchlist_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollectionType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "StatsWatchlistButton_data"
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "StatsTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "CollectionTypeConnection",
                "kind": "LinkedField",
                "name": "collectionWatchlist",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollectionType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isWatching",
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "filters": [
                  "sortBy",
                  "sortAscending"
                ],
                "handle": "connection",
                "key": "StatsTable_collectionWatchlist",
                "kind": "LinkedHandle",
                "name": "collectionWatchlist"
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d20725f470966d4c466e8c980de6bac9",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "getAccount",
            "user",
            "collectionWatchlist"
          ]
        }
      ]
    },
    "name": "StatsTableQuery",
    "operationKind": "query",
    "text": "query StatsTableQuery(\n  $count: Int!\n  $cursor: String\n  $sortBy: CollectionWatchlistSort\n  $sortAscending: Boolean\n  $identity: AddressScalar!\n) {\n  getAccount(address: $identity) {\n    user {\n      collectionWatchlist(after: $cursor, first: $count, sortBy: $sortBy, sortAscending: $sortAscending) {\n        edges {\n          node {\n            slug\n            pinnedDate\n            ...StatsWatchlistButton_data\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment StatsWatchlistButton_data on CollectionType {\n  isWatching\n  pinnedDate\n}\n"
  }
};
})();

(node as any).hash = "766a738abb1360a565eee8ed9ac918c7";

export default node;

import React, { useCallback, useContext, useMemo } from "react"
import { useIsLessThanLg, useIsLessThanXXl } from "@opensea/ui-kit"
import { useWindowSize } from "@react-hook/window-size"
import { graphql } from "react-relay"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import {
  FeatureTable,
  FeatureTableRenderPrimaryProps,
  FeatureTableRenderProps,
} from "@/design-system/FeatureTable"
import { MOCK_ITEMS } from "@/features/rankings/components/RankingsPage"
import { RankingsWatchlistContext } from "@/features/rankings/components/RankingsPage/RankingsPage.react"
import { RankingsRow } from "@/features/rankings/components/RankingsPage/types"
import { isCollectionPinned } from "@/features/rankings/components/RankingsPage/utils"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { StatsTableQuery } from "@/lib/graphql/__generated__/StatsTableQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { StatsFullRowSkeleton } from "../StatsRowSkeletons/StatsFullRowSkeleton.react"
import { StatsRowSkeleton } from "../StatsRowSkeletons/StatsRowSkeleton.react"
import { StatsTableFullRow } from "../StatsTableFullRow"
import { StatsTableHeader } from "../StatsTableHeader"
import { StatsTableRowPrimary } from "../StatsTableRowPrimary/StatsTableRowPrimary.react"

type RankingsTableProps = {
  displayRankIndex?: boolean
  isLoading: boolean
  filters: JSX.Element
  sortableHeaders: JSX.Element[]
  tableKey?: string
  rows: RankingsRow[]
  isWatchlistTab: boolean
  floorPricePercentChangeSupported: boolean
}

const SCREEN_PADDING_XL = 64
const SCREEN_PADDING_SMALL = 16

const LIST_ITEM_LARGE = 52
const LIST_ITEM_SMALL = 24

const FAVORITE_ICON_LARGE = 48
const FAVORITE_ICON_SMALL = 48

const COLUMN_WIDTH_LARGE = 125
const COLUMN_WIDTH_SMALL = 60

// These two variables are just estimates
// because the table uses space-between
const COLUMN_WIDTH_PADDING_LARGE = 40
const COLUMN_WIDTH_PADDING_XL = 60

const NUM_COLUMNS_LARGE = 4
const NUM_COLUMNS_XL = 6

export const StatsTable = React.memo(function StatsTable({
  displayRankIndex = true,
  isLoading,
  filters,
  sortableHeaders,
  rows,
  tableKey,
  isWatchlistTab,
  floorPricePercentChangeSupported,
}: RankingsTableProps) {
  const { pinnedCollections } = useContext(RankingsWatchlistContext)
  const [windowWidth] = useWindowSize()
  const isXXL = !useIsLessThanXXl()
  const isLarge = !useIsLessThanLg()

  const collectionCellMaxWidth = useMemo(() => {
    if (isXXL) {
      return (
        windowWidth -
        SCREEN_PADDING_XL -
        FAVORITE_ICON_LARGE -
        LIST_ITEM_LARGE -
        (COLUMN_WIDTH_LARGE + COLUMN_WIDTH_PADDING_XL) * NUM_COLUMNS_XL
      )
    } else if (isLarge) {
      return (
        windowWidth -
        LIST_ITEM_LARGE -
        FAVORITE_ICON_LARGE -
        (COLUMN_WIDTH_LARGE + COLUMN_WIDTH_PADDING_LARGE) * NUM_COLUMNS_LARGE
      )
    } else {
      return (
        windowWidth -
        SCREEN_PADDING_SMALL -
        FAVORITE_ICON_SMALL -
        LIST_ITEM_SMALL -
        COLUMN_WIDTH_SMALL
      )
    }
  }, [isXXL, isLarge, windowWidth])

  const identity = useConnectedAddress()

  const [watchlistData] = useNoSuspenseLazyLoadQuery<StatsTableQuery>(
    graphql`
      query StatsTableQuery(
        $count: Int!
        $cursor: String
        $sortBy: CollectionWatchlistSort
        $sortAscending: Boolean
        $identity: AddressScalar!
      ) {
        getAccount(address: $identity) {
          user {
            collectionWatchlist(
              after: $cursor
              first: $count
              sortBy: $sortBy
              sortAscending: $sortAscending
            ) @connection(key: "StatsTable_collectionWatchlist") {
              edges {
                node {
                  slug
                  pinnedDate
                  # eslint-disable-next-line relay/must-colocate-fragment-spreads we are actually using it directly?
                  ...StatsWatchlistButton_data
                }
              }
            }
          }
        }
      }
    `,
    {
      identity: identity ?? "",
      count: 100,
    },
    { skip: !identity },
  )

  const header = useMemo(
    () => (
      <StatsTableHeader
        displayRankIndex={displayRankIndex}
        filters={filters}
        isWatchlistTab={isWatchlistTab}
        sortableHeaders={sortableHeaders}
      />
    ),
    [displayRankIndex, filters, sortableHeaders, isWatchlistTab],
  )

  const hasWatchlistData = watchlistData && identity

  const renderFullRow = useCallback(
    (props: FeatureTableRenderProps<RankingsRow>) => {
      if (isLoading) {
        return <StatsFullRowSkeleton displayRankIndex={displayRankIndex} />
      }

      return (
        <StatsTableFullRow
          {...props}
          collectionCellMaxWidth={collectionCellMaxWidth}
          displayRankIndex={displayRankIndex}
          floorPricePercentChangeSupported={floorPricePercentChangeSupported}
          isPinned={
            hasWatchlistData
              ? props.data.slug in pinnedCollections
                ? pinnedCollections[props.data.slug]
                : isCollectionPinned(
                    getNodes(
                      watchlistData.getAccount.user?.collectionWatchlist,
                    ).find(collection => collection.slug === props.data.slug)
                      ?.pinnedDate,
                  )
              : false
          }
          isWatchlistTab={isWatchlistTab}
          watchlistData={
            hasWatchlistData
              ? getNodes(
                  watchlistData.getAccount.user?.collectionWatchlist,
                ).find(collection => collection.slug === props.data.slug) ||
                null
              : null
          }
        />
      )
    },
    [
      isLoading,
      displayRankIndex,
      hasWatchlistData,
      pinnedCollections,
      watchlistData,
      isWatchlistTab,
      collectionCellMaxWidth,
    ],
  )

  const renderPrimary = useCallback(
    (props: FeatureTableRenderPrimaryProps<RankingsRow>) =>
      isLoading ? (
        <StatsRowSkeleton
          displayRankIndex={displayRankIndex}
          isWatchlistTab={isWatchlistTab}
        />
      ) : (
        <StatsTableRowPrimary
          {...props}
          collectionCellMaxWidth={collectionCellMaxWidth}
          displayRankIndex={displayRankIndex}
          isPinned={
            hasWatchlistData
              ? props.data.slug in pinnedCollections
                ? pinnedCollections[props.data.slug]
                : isCollectionPinned(
                    getNodes(
                      watchlistData.getAccount.user?.collectionWatchlist,
                    ).find(collection => collection.slug === props.data.slug)
                      ?.pinnedDate,
                  )
              : false
          }
          isWatchlistTab={isWatchlistTab}
          watchlistData={
            hasWatchlistData
              ? getNodes(
                  watchlistData.getAccount.user?.collectionWatchlist,
                ).find(collection => collection.slug === props.data.slug) ||
                null
              : null
          }
        />
      ),
    [
      isLoading,
      displayRankIndex,
      hasWatchlistData,
      pinnedCollections,
      watchlistData,
      isWatchlistTab,
      collectionCellMaxWidth,
    ],
  )

  return (
    <FeatureTable
      fullRowMinBreakpoint="lg"
      getHref={({ data }) => getCollectionUrl(data)}
      header={header}
      itemHeightEstimate={90}
      items={isLoading ? MOCK_ITEMS : rows}
      key={tableKey}
      renderFullRow={renderFullRow}
      renderMore={() => <></>}
      renderPrimary={renderPrimary}
      role="table"
      showBorder={false}
      showInteractiveStyles
    />
  )
})

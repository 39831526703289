import React from "react"
import { Skeleton } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Flex } from "@/design-system/Flex"
import { ItemSkeleton } from "@/design-system/ItemSkeleton"
import { classNames, media } from "@/styles/styleUtils"

type StatsRowSkeletonProps = {
  isWatchlistTab: boolean
  displayRankIndex: boolean
}

export const StatsRowSkeleton = React.memo(function StatsRowSkeleton({
  isWatchlistTab,
  displayRankIndex,
}: StatsRowSkeletonProps) {
  const ButtonSkeletonContainer = isWatchlistTab
    ? MoreButtonSkeletonContainer
    : WatchlistButtonSkeletonContainer

  return (
    <>
      <Flex
        alignItems="center"
        className="w-full"
        maxHeight="64px"
        paddingLeft={displayRankIndex ? "22px" : "0px"}
        paddingRight={displayRankIndex || !isWatchlistTab ? "16px" : "0px"}
      >
        <Flex marginRight="16px">
          <Skeleton.Block className="h-[46px] w-[46px] rounded-default" />
        </Flex>
        <ItemSkeleton.Content>
          <Skeleton.Line className="mb-[3px] w-[85%] max-w-[200px]" />
          <Skeleton.Line className="mt-[3px] h-[14px] w-1/2 max-w-[140px]" />
        </ItemSkeleton.Content>
        <ItemSkeleton.Side className="w-[80px]">
          <Skeleton.Line className="mb-[3px] w-[85%] max-w-[200px]" />
          <Skeleton.Line className="mt-[3px] h-[14px] w-1/2 max-w-[140px]" />
        </ItemSkeleton.Side>
      </Flex>

      <ButtonSkeletonContainer>
        <Skeleton.Line
          className={classNames("h-6", isWatchlistTab ? "w-3" : "w-6")}
        />
      </ButtonSkeletonContainer>
    </>
  )
})

const MoreButtonSkeletonContainer = styled(Flex)`
  align-items: center;
  padding: 0 10px 0px 10px;
`

const WatchlistButtonSkeletonContainer = styled(Flex)`
  align-items: center;
  padding: 0 16px 0px 4px;
  display: none;
  ${media({
    sm: css`
      display: flex;
    `,
  })}
`

import React from "react"
import { Skeleton, classNames } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { FeatureTable } from "@/design-system/FeatureTable"
import { Flex } from "@/design-system/Flex"
import { media } from "@/styles/styleUtils"

type StatsFullRowSkeletonProps = {
  displayRankIndex: boolean
}

export const StatsFullRowSkeleton = React.memo(function StatsFullRowSkeleton({
  displayRankIndex,
}: StatsFullRowSkeletonProps) {
  return (
    <>
      <FeatureTable.Cell height="64px" width="492px">
        <Skeleton className="h-auto justify-center">
          <Flex alignItems="center" justifyContent="start">
            <Flex
              marginLeft={displayRankIndex ? "58px" : { _: "14px", xl: "8px" }}
              marginRight="24px"
            >
              <Skeleton.Block className="h-[62px] w-[62px] rounded-default" />
            </Flex>
            <Skeleton.Line className="h-[22px] w-4/5" />
          </Flex>
        </Skeleton>
      </FeatureTable.Cell>
      <FeatureTable.Cell>
        <Skeleton className="h-auto justify-center">
          <Skeleton.Row className="justify-end">
            <Skeleton.Line className="h-[22px] w-4/5" />
          </Skeleton.Row>
        </Skeleton>
      </FeatureTable.Cell>
      <FeatureTable.Cell>
        <Skeleton className="h-auto justify-center">
          <Skeleton.Row className="justify-end">
            <Skeleton.Line className="h-[22px] w-4/5" />
          </Skeleton.Row>
        </Skeleton>
      </FeatureTable.Cell>
      <FeatureTable.Cell>
        <Skeleton className="h-auto justify-center">
          <Skeleton.Row className="justify-end">
            <Skeleton.Line className="h-[22px] w-4/5" />
          </Skeleton.Row>
        </Skeleton>
      </FeatureTable.Cell>
      <FeatureTable.Cell width="88px">
        <Skeleton className="h-auto justify-center">
          <Skeleton.Row className="justify-end">
            <Skeleton.Line className="h-[22px] w-4/5" />
          </Skeleton.Row>
        </Skeleton>
      </FeatureTable.Cell>
      <PercentageColumn>
        <Skeleton className="h-auto justify-center">
          <Skeleton.Row className="justify-end">
            <Skeleton.Line className="h-[22px] w-4/5" />
          </Skeleton.Row>
        </Skeleton>
      </PercentageColumn>
      <PercentageColumn>
        <Skeleton className="h-auto justify-center">
          <Skeleton.Row className="justify-end">
            <Skeleton.Line className="h-[22px] w-4/5" />
          </Skeleton.Row>
        </Skeleton>
      </PercentageColumn>
      <Flex alignItems="center" paddingRight={{ _: "8px", xl: "0px" }}>
        <Skeleton className="h-auto min-w-[44px] justify-center">
          <Skeleton.Line
            className={classNames("h-6", !displayRankIndex ? "w-3" : "w-6")}
          />
        </Skeleton>
      </Flex>
    </>
  )
})

const PercentageColumn = styled(FeatureTable.Cell)`
  display: none;
  ${media({
    xxl: css`
      display: flex;
    `,
  })}
`

import React from "react"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { useTranslate } from "@/hooks/useTranslate"
import { EmptyBase } from "./EmptyBase.react"

export const ConnectWalletWatchlist = () => {
  const t = useTranslate("rankings")
  const { startWalletModalAuthFlow } = useWalletModal()

  const TEXT = t(
    "connectWallet.text",
    "Connect your wallet to view your watchlist.",
  )
  const BUTTON_TEXT = t("connectWallet.buttonCTA", "Connect wallet")

  const onClick = () => startWalletModalAuthFlow()
  return (
    <EmptyBase
      buttonProps={{
        onClick,
      }}
      buttonText={BUTTON_TEXT}
      text={TEXT}
    />
  )
}

import { RankingsPageTab } from "@/features/rankings"
import { getTrackingFn } from "../utils"

type RankingsPageSortingParams = {
  category: string
  chain: string
  sortBy: string | null
  columnSort: string
  sortAscending: boolean
  currentTab: RankingsPageTab
  currentPage: number | null
  newValue: {
    columnSort: string
    sortAscending: boolean
  }
}

export type RankingsPageFilterParams = Omit<
  RankingsPageSortingParams,
  "newValue"
> & {
  newValue?: string
  path?: string
}

export const trackSorting = getTrackingFn<RankingsPageSortingParams>(
  "Rankings page sorting selected",
)
export const trackCategoryFilter = getTrackingFn<RankingsPageFilterParams>(
  "Rankings page category filter selected",
)
export const trackChainFilter = getTrackingFn<RankingsPageFilterParams>(
  "Rankings page chain filter selected",
)
export const trackSortByFilter = getTrackingFn<RankingsPageFilterParams>(
  "Rankings page time window filter selected",
)

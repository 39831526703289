import React from "react"
import { Text, FlexColumn } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { FeatureTable } from "@/design-system/FeatureTable"
import { Flex } from "@/design-system/Flex"
import { Tooltip, TOOLTIP_PLACEMENT } from "@/design-system/Tooltip"
import { RankingsRow } from "@/features/rankings/components/RankingsPage/types"
import {
  getPercentFormatted,
  formatCollectionValueChange,
  formatCollectionFloorPrice,
  formatCollectionSales,
  formatCollectionVolume,
} from "@/features/rankings/components/RankingsPage/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { StatsWatchlistButton_data$key } from "@/lib/graphql/__generated__/StatsWatchlistButton_data.graphql"
import { isNewAsset } from "@/lib/helpers/asset"
import { bn } from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"
import { STATS_EMPTY_PRICE_DISPLAY } from "../../../../constants"
import { StatsCollectionCell } from "../StatsCollectionCell.react"
import { StatsTablePrice } from "../StatsTablePrice"
import { StatsWatchlistButton } from "../StatsWatchlistButton"
import { useIsFloorPricePercentChangeEnabled } from "@/hooks/useFlag"

const NUM_COLLECTIONS_ABOVE_FOLD = 15

type StatsTableFullRowProps = {
  data: RankingsRow
  displayRankIndex?: boolean
  watchlistData: StatsWatchlistButton_data$key | null
  isWatchlistTab: boolean
  isPinned: boolean
  collectionCellMaxWidth: number
  floorPricePercentChangeSupported: boolean
}

// TODO: Consolidate all rows into one
export const StatsTableFullRow = ({
  data,
  displayRankIndex = true,
  watchlistData,
  isWatchlistTab,
  isPinned,
  collectionCellMaxWidth,
  floorPricePercentChangeSupported,
}: StatsTableFullRowProps) => {
  const t = useTranslate("statsV2")
  const {
    createdDate,
    windowCollectionStats: stats,
    floorPricePercentChange,
    slug,
    relayId,
  } = data
  // feature flag (should be removed eventually)
  const floorPricePercentChangeEnabled = useIsFloorPricePercentChangeEnabled()

  const isNew = isNewAsset(new Date(createdDate))
  const totalQuantity = parseFloat(data.statsV2.totalQuantity)

  const floorPrice = stats.floorPrice?.unit
    ? formatCollectionFloorPrice(stats.floorPrice.unit)
    : undefined
  const floorPriceSymbol = stats.floorPrice?.symbol

  const floorPriceChange = bn(floorPricePercentChange || 0)
  const floorPriceChangeString = formatCollectionValueChange(
    floorPriceChange.times(100),
  )
  const isFloorPriceChangePositive = floorPriceChange.isGreaterThan(0)

  const volumeString = formatCollectionVolume(bn(stats.volume.unit))
  const volumeSymbol = stats.volume.symbol

  const volumeChange = bn(stats.volumeChange)
  const volumeChangeString = formatCollectionValueChange(
    volumeChange.times(100),
  )
  const isVolumeChangePositive = volumeChange.isGreaterThan(0)

  const numSales = stats.numOfSales
  const numSalesString = formatCollectionSales(bn(numSales))

  const percentUniqueOwners = bn(stats.numOwners / totalQuantity).times(100)
  const percentUniqueOwnersString = getPercentFormatted(percentUniqueOwners)
  const numOwnersString = stats.numOwners.toLocaleString()

  const percentListed = bn(stats.totalListed / stats.totalSupply).times(100)
  const percentListedString = getPercentFormatted(percentListed)
  const numItemsString = stats.totalSupply.toLocaleString()
  const numListedString = stats.totalListed.toLocaleString()
  const hidePercentListed =
    totalQuantity != stats.totalSupply ||
    percentListed.isGreaterThan(100) ||
    totalQuantity === 0

  const collectionTableCell = (
    <FeatureTable.Cell
      justifyContent="flex-start"
      paddingLeft={{ _: "14px", xl: "8px" }}
      width={{ _: "492px", lg: "460px", xl: "492px" }}
    >
      {displayRankIndex && (
        <Block minWidth="52px" paddingX="12px">
          <StatsTextSubtle>
            <Overflow>{data.index + 1}</Overflow>
          </StatsTextSubtle>
        </Block>
      )}
      <StatsCollectionCell
        collection={data}
        collectionCellMaxWidth={collectionCellMaxWidth}
        isPinned={isPinned}
        isWatchlistTab={isWatchlistTab}
        priority={data.index < NUM_COLLECTIONS_ABOVE_FOLD}
      />
    </FeatureTable.Cell>
  )

  const volumeTableCell = (
    <StatsFeatureTableCell>
      <StatsTablePrice symbol={volumeSymbol} value={volumeString} />
    </StatsFeatureTableCell>
  )
  // TODO: i18n +/- colors
  let volumeChangeTableCell = (
    <StatsFeatureTableCell>
      {volumeChange.isEqualTo(0) ? (
        <StatsText>{STATS_EMPTY_PRICE_DISPLAY}</StatsText>
      ) : (
        <Text.Body
          className={isVolumeChangePositive ? "text-green-2" : "text-red-2"}
          size="medium"
          weight="semibold"
        >
          <Overflow>
            {`${isVolumeChangePositive ? "+" : ""}${volumeChangeString}%`}
          </Overflow>
        </Text.Body>
      )}
    </StatsFeatureTableCell>
  )

  if (isNew && volumeChange.isEqualTo(0)) {
    volumeChangeTableCell = (
      <Tooltip
        content={t("stats.tooltip.new", "New")}
        offset={[0, 10]}
        placement={TOOLTIP_PLACEMENT.RIGHT}
      >
        {volumeChangeTableCell}
      </Tooltip>
    )
  }

  const floorPriceTableCell = (
    <StatsFeatureTableCell>
      <StatsTablePrice symbol={floorPriceSymbol} value={floorPrice} />
    </StatsFeatureTableCell>
  )

  let floorPriceChangeTableCell
  if (floorPricePercentChangeSupported && floorPricePercentChangeEnabled) {
    floorPriceChangeTableCell = (
      <StatsFeatureTableCell>
        {floorPriceChange.isEqualTo(0) ? (
          <StatsText>{STATS_EMPTY_PRICE_DISPLAY}</StatsText>
        ) : (
          <Text.Body
            className={
              isFloorPriceChangePositive ? "text-green-2" : "text-red-2"
            }
            size="medium"
            weight="semibold"
          >
            <Overflow>
              {`${
                isFloorPriceChangePositive ? "+" : ""
              }${floorPriceChangeString}%`}
            </Overflow>
          </Text.Body>
        )}
      </StatsFeatureTableCell>
    )

    if (isNew && floorPriceChange.isEqualTo(0)) {
      floorPriceChangeTableCell = (
        <Tooltip
          content={t("stats.tooltip.new", "New")}
          offset={[0, 10]}
          placement={TOOLTIP_PLACEMENT.RIGHT}
        >
          {floorPriceChangeTableCell}
        </Tooltip>
      )
    }
  }

  const salesTableCell = (
    <StatsFeatureTableCell width="88px">
      <StatsText>
        <Overflow>{numSalesString}</Overflow>
      </StatsText>
    </StatsFeatureTableCell>
  )

  const percentUniqueOwnersTableCell = (
    <StatsFeatureTableCell>
      {percentUniqueOwners.isGreaterThan(100) || totalQuantity === 0 ? (
        <StatsText>{STATS_EMPTY_PRICE_DISPLAY}</StatsText>
      ) : (
        <StatsText>
          <Overflow>{`${percentUniqueOwnersString}%`}</Overflow>
        </StatsText>
      )}
    </StatsFeatureTableCell>
  )

  const numOwnersTableCell = (
    <StatsFeatureTableCell>
      <StatsTextSubtle>
        {t("stats.numOwners", "{{numOwnersString}} owners", {
          numOwnersString,
        })}
      </StatsTextSubtle>
    </StatsFeatureTableCell>
  )

  const uniqueOwnersTableCell = (
    <DisplayFlexColumn>
      <Flex textAlign="right">{percentUniqueOwnersTableCell}</Flex>
      <Flex textAlign="right">{numOwnersTableCell}</Flex>
    </DisplayFlexColumn>
  )

  const percentListedTableCell = (
    <StatsFeatureTableCell>
      <StatsText>
        <Overflow>{`${percentListedString}%`}</Overflow>
      </StatsText>
    </StatsFeatureTableCell>
  )

  const numListedTableCell = (
    <StatsFeatureTableCell>
      <StatsTextSubtle>
        {t("stats.numberListed", "{{numListedString}} of {{numItemsString}}", {
          numListedString,
          numItemsString,
        })}
      </StatsTextSubtle>
    </StatsFeatureTableCell>
  )

  const listedTableCell = hidePercentListed ? (
    <DisplayCell justifyContent="flex-end">
      <StatsText>{STATS_EMPTY_PRICE_DISPLAY}</StatsText>
    </DisplayCell>
  ) : (
    <DisplayFlexColumn>
      <Flex textAlign="right">{percentListedTableCell}</Flex>
      <Flex textAlign="right">{numListedTableCell}</Flex>
    </DisplayFlexColumn>
  )

  const watchlistButton = (
    <Flex alignItems="center" paddingRight={{ _: "8px", xl: "0px" }}>
      <StatsWatchlistButton
        collectionId={relayId}
        data={watchlistData}
        isWatchlistTab={isWatchlistTab}
        slug={slug}
      />
    </Flex>
  )

  return (
    <>
      {collectionTableCell}
      {volumeTableCell}
      {volumeChangeTableCell}
      {floorPriceTableCell}
      {floorPriceChangeTableCell}
      {salesTableCell}
      {uniqueOwnersTableCell}
      {listedTableCell}
      {watchlistButton}
    </>
  )
}

export const StatsText = styled(Text).attrs({
  size: "medium",
  weight: "semibold",
  className: "text-primary",
})``

export const StatsTextSubtle = styled(Text).attrs({
  size: "small",
  weight: "semibold",
})``

const StatsFeatureTableCell = styled(FeatureTable.Cell)`
  justify-content: flex-end;
  padding-right: 3px;
`

const DisplayCell = styled(FeatureTable.Cell)`
  display: none;
  ${media({
    xxl: css`
      display: flex;
    `,
  })}
`

const DisplayFlexColumn = ({ children }: { children: React.ReactNode }) => (
  <FlexColumn className="hidden items-end self-center xxl:flex">
    {children}
  </FlexColumn>
)

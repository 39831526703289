import React from "react"
import { Text } from "@opensea/ui-kit"
import { Overflow } from "@/components/common/Overflow"
import { Flex } from "@/design-system/Flex"
import { STATS_EMPTY_PRICE_DISPLAY } from "../../../constants"
import { StatsTextSubtle } from "./StatsTableFullRow"

type StatsTablePriceProps = {
  value?: string
  symbol?: string
  subtleText?: boolean
  size?: "tiny" | "small" | "medium"
}

export const StatsTablePrice = ({
  value,
  symbol,
  subtleText = false,
  size = "medium",
}: StatsTablePriceProps) => {
  const hasValue = !(value === undefined || value === "0")
  const text = hasValue ? value + ` ${symbol}` : STATS_EMPTY_PRICE_DISPLAY
  return (
    <Flex alignItems="center">
      {subtleText ? (
        <StatsTextSubtle>
          <Overflow>{text}</Overflow>
        </StatsTextSubtle>
      ) : (
        <Text.Body className="text-primary" size={size} weight="semibold">
          <Overflow>{text}</Overflow>
        </Text.Body>
      )}
    </Flex>
  )
}

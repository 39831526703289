import React, { useEffect } from "react"
import { isEmpty } from "lodash"
import { usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { getAppInitialProps } from "@/components/app/initialProps"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { AnalyticsContextProvider } from "@/lib/analytics"
import { trackRankingsPage } from "@/lib/analytics/events/pageEvents"
import Wallet from "@/lib/chain/wallet"
import { RankingsPageWatchlist_data$key } from "@/lib/graphql/__generated__/RankingsPageWatchlist_data.graphql"
import { RankingsPageWatchlistPaginationQuery } from "@/lib/graphql/__generated__/RankingsPageWatchlistPaginationQuery.graphql"
import { RankingsPageWatchlistQuery } from "@/lib/graphql/__generated__/RankingsPageWatchlistQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ConnectWalletWatchlist } from "./components/ConnectWalletWatchlist.react"
import { EmptyWatchlist } from "./components/EmptyWatchlist.react"
import {
  DEFAULT_CATEGORY,
  DEFAULT_SORT_BY,
  EVENT_SOURCE,
  isFloorPricePercentChangeSupported,
  PAGE_SIZE,
  SORT_BY_TO_TIME_WINDOW,
} from "./constants"
import { RankingsPage } from "./RankingsPage.react"
import { RankingsPageLayout } from "./RankingsPageLayout.react"
import { WatchlistRankingsPaginationFragment } from "./types"

type RankingsPageWatchlistProps = {
  identity?: string
}

type RankingsPageWatchlistViewProps = {
  data: RankingsPageWatchlist_data$key | null
  identity: string
}

const RankingsPageWatchlistView = ({
  data: rankingsCollectionDataKey,
  identity,
}: RankingsPageWatchlistViewProps) => {
  const paginationFragment = usePaginationFragment<
    RankingsPageWatchlistPaginationQuery,
    RankingsPageWatchlist_data$key
  >(
    /* eslint-disable relay/unused-fields, relay/must-colocate-fragment-spreads */
    graphql`
      fragment RankingsPageWatchlist_data on Query
      @argumentDefinitions(
        identity: { type: "AddressScalar!" }
        cursor: { type: "String" }
        count: { type: "Int!" }
        sortAscending: { type: "Boolean" }
        sortBy: { type: "CollectionWatchlistSort" }
        timeWindow: { type: "StatsTimeWindow" }
        floorPricePercentChange: { type: "Boolean!" }
        chains: { type: "[ChainScalar!]" }
        categories: { type: "[CategoryV2Slug!]" }
      )
      @refetchable(queryName: "RankingsPageWatchlistPaginationQuery") {
        getAccount(address: $identity) {
          user {
            collectionWatchlist(
              after: $cursor
              first: $count
              sortBy: $sortBy
              sortAscending: $sortAscending
              chains: $chains
              categories: $categories
            ) @connection(key: "RankingsPageWatchlist_collectionWatchlist") {
              edges {
                node {
                  createdDate
                  name
                  slug
                  logo
                  isVerified
                  relayId
                  pinnedDate
                  ...StatsCollectionCell_collection
                  ...collection_url
                  ...StatsWatchlistButton_data
                  statsV2 {
                    totalQuantity
                  }
                  windowCollectionStats(statsTimeWindow: $timeWindow) {
                    floorPrice {
                      unit
                      eth
                      symbol
                    }
                    numOwners
                    totalSupply
                    totalListed
                    numOfSales
                    volumeChange
                    volume {
                      unit
                      eth
                      symbol
                    }
                  }
                  floorPricePercentChange(statsTimeWindow: $timeWindow)
                    @include(if: $floorPricePercentChange)
                }
              }
            }
          }
        }
      }
    `,
    /* eslint-enable relay/unused-fields, relay/must-colocate-fragment-spreads */
    rankingsCollectionDataKey,
  )
  return (
    <RankingsPage
      collections={
        (identity &&
          paginationFragment.data?.getAccount.user?.collectionWatchlist) ||
        undefined
      }
      currentTab="watchlist"
      displayRankIndex={false}
      emptyView={<EmptyWatchlist />}
      paginationFragment={
        paginationFragment as WatchlistRankingsPaginationFragment
      }
      showCategoryAndChainFilters
      showMobileSort
      watchlist={
        (identity &&
          paginationFragment.data?.getAccount.user?.collectionWatchlist) ||
        undefined
      }
    />
  )
}

const RankingPageWatchlistLoggedOutView = () => {
  useEffect(() => {
    trackRankingsPage({
      category: DEFAULT_CATEGORY,
      chain: "all",
      currentTab: "watchlist",
      sortBy: null,
      columnSort: "Volume",
      sortAscending: false,
      currentPage: null,
    })
  }, [])
  return (
    <AnalyticsContextProvider
      eventParams={{
        currentTab: "watchlist",
      }}
      eventSource={EVENT_SOURCE}
    >
      <RankingsPageLayout
        chainLabel=""
        chainValue="all"
        currentTab="watchlist"
        pagination={<></>}
        tableContent={<ConnectWalletWatchlist />}
      />
    </AnalyticsContextProvider>
  )
}

export const RankingsPageWatchlist: GraphQLNextPage<
  RankingsPageWatchlistQuery,
  RankingsPageWatchlistProps
> = ({ data }) => {
  const connectedAddress = useConnectedAddress()
  const rankingsCollectionDataKey = isEmpty(data) ? null : data
  if (!connectedAddress) {
    // TODO: we're currently not hitting this view because "/rankings/watchlist"
    // is defined in AUTH_REQUIRED_ROUTES (packages/app/lib/routes.ts) - we should remove from there
    // and use this view
    return <RankingPageWatchlistLoggedOutView />
  }

  return (
    <RankingsPageWatchlistView
      data={rankingsCollectionDataKey}
      identity={connectedAddress}
    />
  )
}

const query = graphql`
  query RankingsPageWatchlistQuery(
    $count: Int!
    $cursor: String
    $sortBy: CollectionWatchlistSort
    $sortAscending: Boolean
    $identity: AddressScalar = "0x0000000000000000000000000000000000000000"
    $noIdentity: Boolean!
    $timeWindow: StatsTimeWindow
    $floorPricePercentChange: Boolean!
    $chains: [ChainScalar!]
    $categories: [CategoryV2Slug!]
  ) {
    ...RankingsPageWatchlist_data
      @arguments(
        identity: $identity
        count: $count
        cursor: $cursor
        sortBy: $sortBy
        sortAscending: $sortAscending
        timeWindow: $timeWindow
        floorPricePercentChange: $floorPricePercentChange
        chains: $chains
        categories: $categories
      )
      @skip(if: $noIdentity)
  }
`

RankingsPageWatchlist.getInitialProps = QP.nextParser(
  {
    category: QP.Optional(QP.CategorySlug),
    chain: QP.Optional(QP.ChainIdentifier),
    sortBy: QP.Optional(QP.string),
  },
  async (
    { category, chain, sortBy },
    ctx,
  ): Promise<
    GraphQLInitialProps<RankingsPageWatchlistQuery, RankingsPageWatchlistProps>
  > => {
    const identity = Wallet.fromContext(ctx).getActiveAccountKey()?.address
    const sortByValue = sortBy || DEFAULT_SORT_BY
    const variables = {
      chains: chain ? [chain] : undefined,
      count: PAGE_SIZE,
      categories: category ? [category] : [],
      identity,
      noIdentity: !identity,
      timeWindow: SORT_BY_TO_TIME_WINDOW.get(sortByValue),
      floorPricePercentChange: isFloorPricePercentChangeSupported(
        sortByValue,
        category,
        true,
      ),
    }

    const appInitialProps = await getAppInitialProps(ctx, {
      query,
      variables,
    })
    return {
      identity,
      ...appInitialProps,
      variables,
    }
  },
)

import React from "react"
import { Media, classNames, ToggleButtonGroup, Tooltip } from "@opensea/ui-kit"
import { EquallySizedChainLogos } from "@/components/svgs/EquallySizedChainLogos"
import { Block } from "@/design-system/Block"
import { Select, SelectOption } from "@/design-system/Select"

type RankingsSelectOption = SelectOption & {
  Logo?:
    | (typeof EquallySizedChainLogos)[keyof typeof EquallySizedChainLogos]
    | null
}

type RankingsSelectProps = {
  className?: string
  options: RankingsSelectOption[]
  value: RankingsSelectOption
  setValue: (value: SelectOption) => void
  testId: string
  appendTo?: Element
  includeTabLabelTooltip?: boolean
  useTabs?: boolean
}

export const RankingSelect = ({
  className,
  options,
  value,
  setValue,
  testId,
  appendTo,
  includeTabLabelTooltip,
  useTabs,
}: RankingsSelectProps) => {
  return (
    <Block
      className={classNames("whitespace-nowrap", className)}
      data-testid={testId}
      display="inline-block"
      width={useTabs ? undefined : { sm: "100%" }}
    >
      {useTabs ? (
        <>
          <Media lessThan="lg">
            <Select
              clearable={false}
              maxHeight="370px"
              options={options}
              overrides={{
                Dropdown: {
                  props: {
                    appendTo,
                    popperOptions: { strategy: "fixed" },
                  },
                },
                ContentItem: {
                  props: {
                    className: "h-9 py-2 px-3 rounded-lg",
                  },
                },
              }}
              readOnly
              value={value.value}
              variant="item"
              onSelect={(newValue: SelectOption | undefined) => {
                if (newValue && newValue !== value) {
                  setValue(newValue)
                }
              }}
            />
          </Media>
          <Media greaterThanOrEqual="lg">
            <ToggleButtonGroup
              value={value.value}
              onValueChange={itemValue => {
                const item = options.find(option => option.value === itemValue)
                if (item?.value && item.value !== value.value) {
                  setValue(item)
                }
              }}
            >
              {options.map(item => {
                const useTooltip =
                  includeTabLabelTooltip && item.value !== "all"

                return (
                  <Tooltip
                    content={useTooltip ? item.label : ""}
                    disabled={!useTooltip}
                    key={item.value}
                  >
                    <ToggleButtonGroup.Button
                      data-testid={`RankingsSelect--chain-option-${item.value}`}
                      value={item.value}
                    >
                      {item.Logo ? (
                        <Block
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                          style={{ width: 24 }}
                        >
                          <item.Logo fill="inherit" />
                        </Block>
                      ) : (
                        item.label
                      )}
                    </ToggleButtonGroup.Button>
                  </Tooltip>
                )
              })}
            </ToggleButtonGroup>
          </Media>
        </>
      ) : (
        <>
          <Select
            clearable={false}
            maxHeight="370px"
            options={options}
            overrides={{
              Dropdown: {
                props: {
                  appendTo,
                  popperOptions: { strategy: "fixed" },
                },
              },
              ContentItem: {
                props: {
                  className: "h-9 lg:h-12 pt-2 pr-3 pb-2 pl-4 px rounded-xl",
                },
              },
              ContentLabelTitle: {
                props: {
                  responsive: true,
                },
              },
            }}
            readOnly
            style={{
              fontSize: "30px !important",
            }}
            value={value.value}
            variant="item"
            onSelect={(newValue: SelectOption | undefined) => {
              if (newValue && newValue !== value) {
                setValue(newValue)
              }
            }}
          />
        </>
      )}
    </Block>
  )
}

import React from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"

type RankingsTableNoItemsProps = {
  message: React.ReactNode
}

export const RankingsTableNoItems = ({
  message,
}: RankingsTableNoItemsProps) => {
  return (
    <CenterAligned className="px-5 py-10">
      {typeof message === "string" ? (
        <Text.Heading asChild responsive size="large">
          <p>{message}</p>
        </Text.Heading>
      ) : (
        message
      )}
    </CenterAligned>
  )
}

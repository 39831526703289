import { useState } from "react"
import { useUpdateEffect } from "react-use"
import { useRouter } from "@/hooks/useRouter"

type UseQueryParamOptions<T> = {
  defaultValue: T
  serializer?: (value: T) => string | undefined
  deserializer?: (value: string) => T
}
export const useQueryParamState = <T>(
  param: string,
  {
    defaultValue,
    serializer = JSON.stringify,
    deserializer,
  }: UseQueryParamOptions<T>,
) => {
  const router = useRouter()
  const deserializedValue = deserializer?.(router.query[param] as string)

  const [value, setValue] = useState<T>(deserializedValue ?? defaultValue)

  useUpdateEffect(() => {
    if (deserializedValue === value) {
      return
    }
    const serializedValue = serializer(value)
    if (serializedValue === undefined) {
      delete router.query[param]
    } else {
      router.query[param] = serializedValue
    }
    router.push({ query: router.query })
  }, [value])

  return [value, setValue] as const
}

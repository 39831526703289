import React, { ComponentProps, useRef } from "react"
import { Text, Media, Container } from "@opensea/ui-kit"
import styled from "styled-components"
import { AccountOrCollectionPageContextProvider } from "@/components/layout/AccountOrCollectionPage/utils/AccountOrCollectionPageContextProvider.react"
import { Z_INDEX } from "@/constants/zIndex"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { TabNavigation } from "@/design-system/TabNavigation"
import { OpenSeaHead, pageTitle } from "@/features/seo"
import { PageLinkedDataJson } from "@/features/seo/components/Head/useLinkedDataJson"
import { useTranslate } from "@/hooks/useTranslate"
import { NAV_HEIGHT_PX } from "@/styles/variables"
import { RankingsPageTab } from "./types"
import { useRankingsPageCanonicalUrl } from "./utils"

interface RankingsPageLayoutProps {
  currentTab: RankingsPageTab
  tableContent: React.ReactNode
  pagination: React.ReactNode
  chainLabel: string
  chainValue: string
}

export const RankingsPageLayout = ({
  currentTab,
  tableContent,
  pagination,
  chainLabel,
  chainValue,
}: RankingsPageLayoutProps) => {
  const t = useTranslate("rankings")
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const canonicalUrl = useRankingsPageCanonicalUrl(chainValue)

  const COLLECTION_STATS_TABS: ComponentProps<typeof TabNavigation>["tabs"] = [
    {
      id: "trending",
      label: t("stats.table.trending.header", "Trending"),
      path: "/trending",
    },
    {
      id: "top",
      label: t("stats.table.top.header", "Top"),
      path: "",
    },
    {
      id: "watchlist",
      label: t("stats.table.watchlist.header", "Watchlist"),
      path: "/watchlist",
    },
  ]

  const headerContent = (
    <Flex paddingBottom="24px" paddingTop="64px">
      <Media greaterThanOrEqual="lg">
        <Text.Display size="large">
          {t("header.title", "Collection stats")}
        </Text.Display>
      </Media>
      <Media lessThan="lg">
        <Text.Display size="medium">
          {t("header.title", "Collection stats")}
        </Text.Display>
      </Media>
    </Flex>
  )

  const mainContent = (
    <>
      {headerContent}
      <TabContainer margin={{ _: "0 -16px", xl: 0 }}>
        <TabNavigation
          baseUrl="/rankings"
          className="px-4 py-0 xl:px-0"
          currentTab={currentTab}
          isLoading={false}
          tabs={[...COLLECTION_STATS_TABS]}
        />
      </TabContainer>
      {tableContent}
    </>
  )
  const RANKINGS_DESCRIPTION = t(
    "rankings.head.description",
    `Browse through the top {{chainLabel}}NFT collections ranked by volume, floor price and other statistics. {{chainLabel}}NFT marketplace.`,
    { chainLabel },
    { forceString: true },
  )

  const RANKINGS_TITLE = t(
    "rankings.head.title",
    "Top {{chainLabel}}NFTs",
    { chainLabel },
    { forceString: true },
  )

  const linkedDataSchema: PageLinkedDataJson = {
    "@type": "Table",
    about: RANKINGS_TITLE,
  }

  return (
    <AccountOrCollectionPageContextProvider>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <OpenSeaPage className="Rankings" hideFooter>
        <OpenSeaHead
          description={RANKINGS_DESCRIPTION}
          linkedDataSchema={linkedDataSchema}
          title={pageTitle(RANKINGS_TITLE)}
          url={canonicalUrl}
        />
        <StatsContainer
          className={!pagination ? "mb-8 xl:mb-16" : undefined}
          ref={tableContainerRef}
        >
          {mainContent}
        </StatsContainer>
        {pagination}
      </OpenSeaPage>
    </AccountOrCollectionPageContextProvider>
  )
}

const StatsContainer = styled(Container)`
  max-width: 1920px;
`

const TabContainer = styled(Block)`
  position: sticky;
  top: ${NAV_HEIGHT_PX -
  15}px; // 1px for the border, 14px to compensate for whitespace in top nav
  z-index: ${Z_INDEX.PHOENIX_HEADER};
  margin-top: 12px;
  background-color: ${props => props.theme.colors.base1};
  padding-bottom: 24px;
`
